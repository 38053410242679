import React, { useEffect } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Box,
  Card,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import { motion, useAnimation } from "framer-motion";
import { SmallZoomEntry } from "../../Utils/AnimationEffects";
import { useInView } from "react-intersection-observer";
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  featuresBoxBase: {
    width: "100%",
    position: "relative",
    borderRadius: 0,
    background: "#f7f7f7",
    padding: "40px 20%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5%",
      paddingRight: "5%",
    }
  },
  featureCard: {
    background: "transparent",
    padding: "0",
  },
  featureDetails: {
    textAlign: "center",
  },
  cardContent: {
    padding: '20px 0px 0px 0px !important',
  },

  title: {
    textAlign: "center",
    fontSize: "1.3rem",
    fontWeight: "Bold",
    color: '#333333',
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
      lineHeight: "1.3rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.125rem",
      lineHeight: "1.3rem",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  desc: {
    color: "#000000",
    fontSize: "1rem",
    "@media (max-width: 1024px)": {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("md")]: {
      // fontSize: 15,
    },
    [theme.breakpoints.down("sm")]: {
      // fontSize: 12,
      marginTop: 10,
      lineHeight: "1.3rem"
    },
  },

  featureItem: {
    padding: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      padding: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(3),
    },
  },
  media: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
      paddingTop: 0,
      paddingBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(8),
      paddingTop: 0,
      paddingBottom: 0,
    },
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const FeaturesBox = (props) => {
  const { values } = props;
  const { features } = values;
  const classes = useStyles();
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false });

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start("visible");
      }, 300);
    }
  }, [controls, inView]);

  return (
    <>
      <div className={classes.featuresBoxBase}>
            <Grid
              container
              justifyContent="center"
              ref={ref}
              spacing={0}
            >
              {features &&
                features.length > 0 &&
                features.map((feature, featureKey) => {
                  return (
                    <Grid
                      item
                      md={feature.width ? feature.width : 4}
                      sm={8}
                      xs={11}
                      key={featureKey}
                      className={classes.featureItem}
                    >
                      <motion.div
                        animate={controls}
                        initial="hidden"
                        variants={{
                          visible: { ...SmallZoomEntry.animate, opacity: 1 },
                          hidden: {
                            ...SmallZoomEntry.initial,
                            scale: 0.8,
                            opacity: 0,
                          },
                        }}
                        transition={{
                          duration: 1,
                        }}
                      >
                        <Card className={classes.featureCard} elevation={0}>
                          <CardMedia
                            component="img"
                            className={classes.media}
                            image={`${feature.featureImage[0]}`}
                            
                          />
                          <CardContent className={classes.cardContent}>
                            <Box display="flex" justifyContent="center">
                              <div className={classes.featureDetails}>
                                <Typography className={classes.title}>
                                  {parse(feature.primaryHeading && feature.primaryHeading[0] ? feature.primaryHeading[0]: "")}
                                </Typography>
                                <Typography className={classes.desc}>
                                  {parse(feature.secondaryHeading && feature.secondaryHeading[0] ? feature.secondaryHeading[0]: "")}
                                </Typography>
                              </div>
                            </Box>
                          </CardContent>
                        </Card>
                      </motion.div>
                    </Grid>
                  );
                })}
            </Grid>
      </div>
    </>
  );
};

export default FeaturesBox;
