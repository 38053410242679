import React, { useEffect } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
} from "@material-ui/core";
import CirlceImage from "../../../images/shape1.png";
import { motion, useAnimation } from "framer-motion";
import { SmoothZoomEntry } from "../../Utils/AnimationEffects";
import { useInView } from "react-intersection-observer";
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${CirlceImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    minHeight: 410,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    "@media (min-width: 1690px)": {
      minHeight: 430,
    },
    "@media (max-width: 1440px)": {
      minHeight: 345,
    },
    "@media (max-width: 1024px)": {
      minHeight: 260,
    },
  
    [theme.breakpoints.up("xl")]: {
      minHeight: 480,
    },

    [theme.breakpoints.only("sm")]: {
      minHeight: 530,
    },
    [theme.breakpoints.only("xs")]: {
      minHeight: 380,
    },

    

  },
  stepBoxBase: {
    width: "100%",
    position: "relative",
    borderRadius: 0,
    paddingBottom: theme.spacing(2),
  },

  headerSection: {
    marginTop: 60,
    [theme.breakpoints.down("md")]: {
      marginTop: 60,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    },
  },
  headerText: {
    textAlign: "center",
    color: "#6D6D6D",
    fontSize: "2.2rem",
    paddingBottom: "80px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.875rem",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingBottom: "20px",
    },
  },

  storyDetails: {
    textAlign: "center",
  },
  title: {
    textAlign: "center",
    fontSize: "1.4rem",
    fontWeight: "Bold",
    paddingBottom: "10px",
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.125rem",
      lineHeight: "1.3rem",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  description: {
    color: "#000000",
    fontSize: "1rem",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    "@media (max-width: 1024px)": {
      fontSize: "0.8rem",
    },

    [theme.breakpoints.down("md")]: {
      // fontSize: 15,
    },
    [theme.breakpoints.down("sm")]: {
      // fontSize: 12,
      marginTop: 10,
      lineHeight: "1.3rem",
    },
  },

  storiesContainer: {
    marginLeft: "10%",
    marginRight: "10%",
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      marginRight: "0",
    },
  },

  loveBox: {
    padding: theme.spacing(6),
    paddingTop: 0,
    [theme.breakpoints.up("md")]: {
      padding: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
  },
}));

export default function SimpleCard(props) {
  const { values, desktop } = props;
  const { spacing } = values;
  const classes = useStyles();
  const { stepstory } = values;
  const controls = useAnimation();
  const [inView] = useInView({ triggerOnce: false });

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start("visible");
      }, 300);
    }
  }, [controls, inView]);

  return (
    <>
      <div className={classes.stepBoxBase}>
        <Grid container spacing={0} direction="row">
          {values.primaryHeading && (
            <Grid item xs={12} className={classes.headerSection}>
              <Typography className={classes.headerText} variant={"h3"}>
                {values.primaryHeading && values.primaryHeading[0]
                  ? values.primaryHeading[0]
                  : ""}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} className={classes.storiesContainer}>
            <Grid
              container
              justifyContent="center"
              spacing={desktop ? (spacing ? spacing : 0) : 0}
            >
              {stepstory &&
                stepstory.length > 0 &&
                stepstory.map((step, stepKey) => {
                  return (
                    <Grid
                      item
                      md={step.width ? step.width : 4}
                      sm={8}
                      xs={11}
                      key={stepKey}
                      className={classes.loveBox}
                    >
                      <motion.div
                        animate={controls}
                        initial="hidden"
                        {...SmoothZoomEntry}
                      >
                        <Card
                          className={(classes.root)}
                          style={{
                            backgroundImage: `url(${step.backgroundImage})`,
                          }}
                        >
                          <CardContent>
                            <Box display="flex" justifyContent="center">
                              <div className={classes.storyDetails}>
                                <Typography className={classes.title}>
                                  {parse(
                                    step.title && step.title[0]
                                      ? step.title[0]
                                      : ""
                                  )}
                                </Typography>
                                <Typography className={classes.description}>
                                  {step.description && step.description[0]
                                    ? step.description[0]
                                    : ""}
                                </Typography>
                              </div>
                            </Box>
                          </CardContent>
                        </Card>
                      </motion.div>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
