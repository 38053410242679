import React from 'react'
import { Helmet } from 'react-helmet'
import { makeStyles, Typography, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(6),
    minHeight: 'calc(80vh - 99px)',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center'
  },
  heading: {
    width: '100%',
    textAlign: 'center'
  },
  text: {
    color: '#888888',
    paddingBottom: theme.spacing(3)
  },
  button: {
    borderRadius: theme.spacing(4),
    textTransform: 'none'
  },
  icon: {
    color: '#888888',
    fontSize: theme.spacing(10)
  }
}))

const NotFound = () => {
  const classes = useStyles()
  const history = useHistory()
  const goHome = () => {
    history.push('/')
  }
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Partners - 404</title>
        <meta name='404 page' content='404 Page not found' />
      </Helmet>
      <div className={classes.content}>
        <div className={classes.heading}>
          <WarningRoundedIcon className={classes.icon} />
          <Typography variant={'h2'} className={classes.text}>We couldn't find the page.</Typography>
          <Button variant={'outlined'} onClick={goHome} className={classes.button}>Go back home</Button>
        </div>
      </div>
    </>
  )
}

export default NotFound
