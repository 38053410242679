import React from 'react';
import { makeStyles, Typography, Link, } from '@material-ui/core';
import { motion } from 'framer-motion';
import Carousel from "react-multi-carousel";

const SmoothLeftToRight = {
  initial: {
    x: -20,
    y: 0,
    opacity: 0,
  },
  animate: {
    x: 0,
    y: 0,
    opacity: 1,
  },
  transition: { duration: 0.7 },
};

const useStyles = makeStyles((theme) => ({
  heroBaseStyle: {
    width: '100%',
    height: '700px',
    [theme.breakpoints.down('sm')]: {
      height: '575px',
      gridTemplateColumns: '1fr',
    },
    marginBottom: '20px',
  },
  heroSlide: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  heroBanner: {
    height: 500,
    "@media (max-width: 1440px)": {
      height: 450,
    },
    [theme.breakpoints.down("sm")]: {
      height: 260,
    },
    '&$partnerBanner': {
      backgroundImage: `url(https://gfc.target.com/partners/partners-image-desktop.png)`,
      backgroundPosition: 'center', 
      backgroundSize: 'contain', 
      backgroundRepeat: 'no-repeat',
    },
    '&$creatorBanner': {
      backgroundImage: `url(https://gfc.target.com/partners/creators-image-desktop.png)`,
      backgroundPosition: 'center', 
      backgroundSize: 'contain', 
      backgroundRepeat: 'no-repeat',
     }
  },
  partnerBanner: {
    // backgroundColor: '#CC0000',
  },
  creatorBanner: {
    // backgroundColor: '#EEEEEE',
  },
  heroTextWrapper: {
  },
  heroTextArea: {
    minHeight: '220px',
    margin: '50px 0 30px 15%',
    [theme.breakpoints.down('sm')]: {
      margin: '0px 10% 0px 10%',
      minHeight: '250px',
      textAlign: 'center',
    },
  },
  heroHeadingText: {
    fontSize: "5.2rem",
    lineHeight: "5.5rem",
    "@media (max-width: 1440px)": {
      fontSize: "4.5rem",
      lineHeight: "4.8rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
      lineHeight: "2.2rem",
    },
  },
  heroSubText: {
    margin: "20px 0",
    fontSize: "1.9rem",
    lineHeight: "2.2rem",
    "@media (max-width: 1440px)": {
      fontSize: "1.2rem",
      lineHeight: "1.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      lineHeight: "1rem",
    },
  },
  blackText: {
    color: '#000000'
  },
  whiteText: {
    color: '#FFFFFF'
  },
  redText: {
    color: '#CC0000'
  },
  greyText: {
    color: '#6D6D6D'
  },
  exploreNowButton: {
    margin: '10px 0',
    width: 'fit-content',
    textTransform: "none",
    borderRadius: 50,
    fontSize: "1.2rem",
    borderColor: "#CC0000",
    lineHeight: 1.75,
    border: 'none',
    backgroundColor: "#CC0000",
    color: "#FFFFFF",
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginRight: 30,
    [theme.breakpoints.down("sm")]: {
      "&:first-child": {
        margin: '10px 0',
        fontSize: "1rem",
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
  },
}));

const HeroWithImage = (props) => {
  const { values } = props;
  const classes = useStyles();

  return (
    <div>
      <Carousel
        className={classes.heroBaseStyle} 
        responsive={{
          allScreens: {
            breakpoint: { max: 400000, min: 0 },
            items: 1,
          }
        }}
        infinite
        autoPlay={false}
        showDots
        arrows={false}
      >
        {values.banners.map( (bannerData, index) => (
          <div className={classes.heroSlide} key={index}>
            <div className={classes.heroTextArea}>
              <motion.div {...SmoothLeftToRight}>
                <Typography className={`${classes.heroHeadingText} ${index===0 ? classes.blackText : classes.blackText}`} variant={"h2"}>
                  {bannerData.primaryHeading} <br />{" "}
                  <span className={`${classes.heroHeadingText} ${index===0 ? classes.blackText : classes.blackText}`}>
                      {bannerData.secondaryHeading}
                  </span>
                </Typography>
                <Typography color={"primary"} className={`${classes.heroSubText} ${index===0 ? classes.blackText : classes.blackText}`}>
                  {bannerData.subTextPrimary} <br />{" "}
                  <span className={`${classes.heroSubText} ${index===0 ? classes.blackText : classes.blackText}`}>
                    {bannerData.subTextSecondary}
                  </span>
                </Typography>
                {(index===1) && 
                  <Link
                    href="/creators"
                    aria-label="Join Now (Opens in new tab)"
                  >
                    <button className={classes.exploreNowButton}>Explore Now</button>
                  </Link>
                }
              </motion.div>
            </div>
            <div className={`${classes.heroBanner} ${index === 0 ? classes.partnerBanner : classes.creatorBanner}`}></div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default HeroWithImage;
