import React from "react";
import {
  makeStyles,
  Typography,
  Link,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  joinCreatorsBoxBase: {
    width: "100%",
    height: "400px",
    position: "relative",
    borderRadius: 0,
    background: "#E8E8E8",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    }
  },
  bannerBox:{
    backgroundImage: `url(https://gfc.target.com/cmstitestpoc/radial-graphic01.jpg)`,
    backgroundPosition: 'center', 
    backgroundSize: 'cover', 
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down("sm")]: {
      display: 'none',
    }
  },
  joinNowWrapper:{
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down("sm")]: {
      margin: "0 20px",
    }
  },
  joinNowBox:{
    marginLeft: '30px',
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }
  },
  primaryHeading: {
    color: "#000000",
    fontWeight: 200,
    fontSize: "2rem",
    lineHeight: "2rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    }
  },
  secondaryHeading: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "2rem",
    lineHeight: "2.5rem",
    paddingBottom: '15px',
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    }
  },
  joinNowButton:{
    margin: '20px 0 0 0',
    width: 'fit-content',
    textTransform: "none",
    borderRadius: 50,
    borderColor: "#CC0000",
    lineHeight: 1.75,
    border: 'none',
    color: "#FFFFFF",
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: "#CC0000",
    [theme.breakpoints.down("sm")]: {
      "&:first-child": {
        fontSize: '1.2rem',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: theme.spacing(1.25),
        paddingBottom: theme.spacing(1.25),
      },
    },
  },
}));

const JoinCreatorsBox = (props) => {
  const { values } = props;
  const classes = useStyles();

  return (
    <div className={classes.joinCreatorsBoxBase}>
      <div className={classes.bannerBox}></div>
      <div className={classes.joinNowWrapper}>
        <div className={classes.joinNowBox}>
          <Typography className={classes.primaryHeading} variant={"h2"}>
            {values.primaryHeading}
          </Typography>
          <Typography className={classes.secondaryHeading} variant={"h2"}>
            {values.secondaryHeading}
          </Typography>
          <Link
            href="https://creator.target.com/onboarding"
            target={"_blank"}
            aria-label="Join Now (Opens in new tab)"
          >
            <button className={classes.joinNowButton}>
              Apply Now
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default JoinCreatorsBox;
