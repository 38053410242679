import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link, Grid } from "@material-ui/core";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { BrandBannerEffect } from "../../Utils/AnimationEffects";

import PartnersBackground from '../../../images/partner-brand-v1.png';
import CreatorsBackground from '../../../images/creator-brand-v1.png';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#CC0000",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
    color: "#777777",
    display: "flex",
    padding: "10px",
    paddingBottom: "20px",
    alignItems: "center",
    marginLeft: "10%",
    marginRight: "10%",
    borderRadius: "25px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      paddingLeft: "0px !important",
      margin: theme.spacing(3),
    },
  },
  partnerSlide:{
    borderRadius: 20,
    background: '#CC0000',
    padding: '50px 0',
    [theme.breakpoints.down("sm")]: {
      padding: '30px 0',
    },
  },
  creatorSlide:{
    borderRadius: 20,
    background: '#F3F1F2',
    padding: '50px 0',
    [theme.breakpoints.down("sm")]: {
      padding: '30px 0',
    },
  },
  bannerImage: {
    width: '100%',
    height: 100,
    "@media (max-width: 1440px)": {
      height: 80,
    },
    [theme.breakpoints.down("sm")]: {
      height: 65,
    },
    '&$partnerBanner': {
      backgroundImage: `url(${PartnersBackground})`,
      backgroundPosition: 'center', 
      backgroundSize: 'contain', 
      backgroundRepeat: 'no-repeat',
    },
    '&$creatorBanner': {
      height: 75,
      backgroundImage: `url(${CreatorsBackground})`,
      backgroundPosition: 'center', 
      backgroundSize: 'contain', 
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.up("sm")]: {
        height: 110,
      },
    }
  },
  partnerBanner:{
  },
  creatorBanner:{
  },
  blackText: {
    color: '#000000'
  },
  whiteText: {
    color: '#FFFFFF'
  },
  heading:{
    fontSize: "2rem",
    lineHeight: "2rem",
    fontWeight: 'bolder',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      lineHeight: "1.5rem",
    },
  },
  subHeading:{
    fontSize: "2rem",
    lineHeight: "2rem",
    fontWeight: 'normal',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      lineHeight: "1.5rem",
    },
  },
  button: {
    margin: '20px 0 0 0',
    width: 'fit-content',
    textTransform: "none",
    borderRadius: 20,
    lineHeight: 1.75,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      "&:first-child": {
        margin: '20px 0 0 0',
      },
    },
    '&$partnerButton': {
      borderColor: "#FFFFFF",
      backgroundColor: "#FFFFFF",
      color: "#CC0000",
    },
    '&$creatorButton': {
      borderColor: "#CC0000",
      backgroundColor: "#CC0000",
      color: "#FFFFFF",
    }
  },
  partnerButton: {
  },
  creatorButton: {
  },
  contentPlace: {
    height: "auto",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  contentWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
  }
}));

export default function BrandBanner(props) {
  const { values } = props;
  const { partnersLink, creatorsLink } = values;
  const classes = useStyles();
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false });

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start("visible");
      }, 300);
    }
  }, [controls, inView]);

  return (
    <div ref={ref} style={{padding: 10}}>
      <motion.div
        animate={controls}
        initial="hidden"
        {...BrandBannerEffect}
        className={classes.bannerText}
      >
        <Grid container spacing={3} justifyContent="center" style={{padding: '40px 0'}}>
          {['Partner', 'Creator'].map( (brandData, index) => (
            <Grid item xs={12} md={5} className={classes.contentPlace} key={brandData}>
              <div className={ index===0 ? classes.partnerSlide : classes.creatorSlide}>
                <Grid container spacing={0}>
                  <Grid item xs={6} className={classes.contentPlace}>
                    <div style={{ display: 'flex', alignItems: 'center', height: '100%'}}>
                      <div className={`${classes.bannerImage} ${index === 0 ? classes.partnerBanner : classes.creatorBanner}`}></div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.contentWrapper}>
                      <Typography variant="h3" className={`${classes.subHeading} ${index === 0 ? classes.whiteText : classes.blackText}`}>
                        Become a
                      </Typography>
                      <Typography variant="h3" className={`${classes.heading} ${index === 0 ? classes.whiteText : classes.blackText}`}>
                        Target {brandData}
                      </Typography>
                      <Link
                        href={index === 0 ? partnersLink : creatorsLink}
                        target={"_blank"}
                      >
                        <button className={`${classes.button} ${index === 0 ? classes.partnerButton : classes.creatorButton}`}>Apply Now</button>
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          ))}
        </Grid>
      </motion.div>
    </div>
  );
}
