import React from 'react'
import { Helmet } from 'react-helmet'
import PrivacyPage from '../SiteData/privacyPage'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Grid } from '@material-ui/core'
import TextBox from '../ComponentsList/TextBox/TextBox'
import BreadCrumb from '../ComponentsList/Breadcrumb/Breadcrumb'



const useStyles = makeStyles((theme) => ({

  pageLayout: {
    paddingLeft: theme.spacing(24),
    paddingRight: theme.spacing(24),
    paddingBottom: theme.spacing(5),
    minHeight: '650px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      minHeight: '0',
      // paddingTop: theme.spacing(3),
    }
  },

}))

const Privacy = () => {
  const {breadcrumb, textBoxOne} = PrivacyPage
  const classes = useStyles()
  const desktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  return (
    <>
    <Helmet>
    <meta charSet='utf-8' />
    <title>Terms and Conditions: Target Partners</title>
    {/* Home page specific meta tags for targeted SEO, this overrides the main tags and won't if it's not included here */}
    <meta name='description' content='Terms and conditions ensure parties understand their contractual rights and obligations. Please read them carefully.' />
    {/* Any specific keywords added here will override the main keywords */}
    <meta name='keywords' content='Target Partners, Influencer, Affiliates, Partners Program, Affiliates Program, Terms and conditions' />
    {/*Global site tag (gtag.js) - Google Analytics*/}
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-845PXTZEFH"></script>
    <script>{`
       window.dataLayer = window.dataLayer || [];
   function gtag(){dataLayer.push(arguments);}
   gtag('js', new Date());
   gtag('config', 'G-845PXTZEFH');
    `}
    </script> 

  </Helmet>
      <div>
        <BreadCrumb values={breadcrumb} desktop={desktop} />
       <Grid container className={classes.pageLayout}>
        <Grid item xs={12} className={classes.contentArea}>
          <TextBox values={textBoxOne} /> 
        </Grid>
      </Grid>
            </div>
     
    </>
  )
}

export default Privacy

