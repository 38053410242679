import React, {useEffect} from 'react'
import { makeStyles, Typography, Divider } from '@material-ui/core'
import ImageBox from './../ImageBox/ImageBox'
import { Link as RLink } from 'react-router-dom'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { motion, useAnimation } from 'framer-motion'
import { TextBoxEffects } from '../../Utils/AnimationEffects'
import { useInView } from "react-intersection-observer"
import parse from 'html-react-parser';


const useStyles = makeStyles((theme) => ({
    textBox: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(3) + 'px !important',
            marginTop: '0 !important'
        }
    },
    headingStyle: {
        color: '#6D6D6D',
        textAlign: 'left',
        paddingBottom: 18,
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.48rem !important',
            paddingBottom: theme.spacing(1) + 'px !important',
            // paddingBottom: 0
        }
    },
    descStyle: {
        color: '#333333',
        textAlign: 'left',
        paddingTop: 25,
        letterSpacing: 0,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 10,
            fontSize: '1rem',
            lineHeight: '30px',
        },
        '& p': {
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(3),
            color: '#333333',
            fontSize: '1.1rem',
        },
        '& ul li': {
            fontSize: '1.1rem',
        },
        [theme.breakpoints.down('xs')]: {
            '& p': {
                fontSize: '0.875rem !important',
            },
            '& ul li': {
                fontSize: '0.875rem !important',
            },
            '& p a': {
                fontSize: '0.675rem !important',
            },
        }
    },

    link: {
        textAlign: 'center',
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(6)
    },
    linkButton: {
        border: '1px solid',
        color: '#CC0000',
        borderRadius: 20,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7),
        textDecoration: 'none',
        fontSize: 16,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        }
    },
    
}))

const TextBox = (props) => {
    const { values, desktop } = props
    const { heading, desc, seperator, styling, imageBox, linkToPage } = values
    const classes = useStyles()
    const controls = useAnimation()
    const [ref, inView] = useInView({triggerOnce: false});

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                controls.start("visible");
            }, 300)
        }
    }, [controls, inView])

    const deafultHeadingStyle = {}

    const deafultDescStyle = {}

    const defaultDividerStyle = {}

    const defaultTextBoxStyle = {}

    const headingStyle = styling && styling.heading ? Object.assign({}, deafultHeadingStyle, styling.heading) : deafultHeadingStyle
    const descStyle = styling && styling.desc ? Object.assign({}, deafultDescStyle, styling.desc) : deafultDescStyle
    const dividerStyle = styling && styling.divider ? Object.assign({}, defaultDividerStyle, styling.divider) : defaultDividerStyle
    const textBoxStyle = styling && styling.textBox ? Object.assign({}, defaultTextBoxStyle, styling.textBox) : defaultTextBoxStyle

    return (
        <>
            <div ref={ref}>
                <motion.div                                     
                    animate={controls}
                    initial="hidden"
                    {...TextBoxEffects.text}
                    className={classes.bannerText}
                >
                    <div className={classes.textBox} style={textBoxStyle}>
                        {heading && <Typography style={headingStyle} className={classes.headingStyle} variant={"h2"}>{heading}</Typography>}
                        {seperator && <Divider style={dividerStyle} className={classes.dividerStyle}/>}
                        {desc && <div style={descStyle} className={classes.descStyle}>{parse(desc)}</div>}
                    </div>
                    <motion.div                                     
                        animate={controls}
                        initial="hidden"
                        {...TextBoxEffects.image}
                        className={classes.bannerText}
                    >
                        {imageBox && <ImageBox values={imageBox} desktop={desktop} />}
                    </motion.div>
                </motion.div>
                
                <motion.div                                     
                    animate={controls}
                    initial="hidden"
                    {...TextBoxEffects.text}
                    className={classes.bannerText}
                >
                {linkToPage && (
                    <div className={classes.link}>
                        <RLink className={classes.linkButton} to={linkToPage.slug}>{linkToPage.label}<ArrowRightIcon className={classes.rightArrow} /></RLink>
                    </div>
                )}
                </motion.div>
            </div>
        </>
    )
}

export default TextBox
