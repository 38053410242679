const privacyPage = {
  breadcrumb: {
      tree: [
          { label: "Home", slug: "/" },
          { label: "Privacy Policy", active: true },
      ],
  },
  textBoxOne: {
      heading: "Privacy Policy",
      desc: `
      <p>Target needs to collect, use, share, disclose, and retain your personal information to establish, manage, terminate or otherwise administer the Target Affiliate program. 
      Examples of how your personal information may be used include processing your application, paying commission, administering surveys, sending program updates and communications via email, 
      improving the design of our web pages, products and services offered, general correspondence and complying with legal obligations. Target will handle your personal information
       in accordance with applicable law. Please be advised that your personal information may be disclosed to third parties, including service providers who assist with the Target Affiliate program. 
       By providing your personal information, you acknowledge and consent to its collection, use, sharing, disclosing and retention for the stated purposes. If you have privacy questions or concerns, please 
       contact <a href="mailto:privacy.request@target.com">Privacy.request@target.com.</a></p>

      <p>California Residents: For state-specific information, please see the California Business Privacy Notice at <a href="https://corporate.target.com/about/contact-help/California-Business-Privacy-Notice">https://corporate.target.com/about/contact-help/California-Business-Privacy-Notice.</a></p>
  
      `,
      styling: {
          textBox: {
              padding: 0,
          },
          heading: {
              color: "#6D6D6D",
              padding: 18,
              fontSize: "2rem",
              textAlign: "center",
          },
          ul: {
              padding: 0,
              margin: 0,
          },
      },
  },
};

export default privacyPage;
