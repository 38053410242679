import React from "react";
import { makeStyles, Typography, Link } from "@material-ui/core";
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  infoBox: {
    textAlign: "center",
    padding: theme.spacing(4),
    marginBottom: theme.spacing(3),
    fontSize: "2rem",
  },

  descStyle: {
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
      lineHeight: "2.2rem",
    }
  },
}));

const Info = (props) => {
  const { values } = props;
  const { desc, styling, linkToPage } = values;
  const classes = useStyles();

  const deafultDescStyle = {};
  const defaultInfoBoxStyle = {};
  const defaultLinkStyle = {};

  const descStyle =
    styling && styling.desc
      ? Object.assign({}, deafultDescStyle, styling.desc)
      : deafultDescStyle;
  const infoBoxStyle =
    styling && styling.infoBox
      ? Object.assign({}, defaultInfoBoxStyle, styling.infoBox)
      : defaultInfoBoxStyle;
  const linkStyle =
    styling && styling.link
      ? Object.assign({}, defaultLinkStyle, styling.link)
      : defaultLinkStyle;

  return (
    <>
      <div>
        <div className={classes.infoBox} style={infoBoxStyle}>
          <Typography style={descStyle} className={classes.descStyle}>
            {parse(desc)}
            {linkToPage && (
              <Link
                className={classes.linkButton}
                href={linkToPage.slug}
                style={linkStyle}
              >
                {linkToPage.label}
              </Link>
            )}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Info;
