import CreatorsBackgroundDesktop from '../../images/hello_creators_background_desktop.png';
import CreatorsBackgroundMobile from '../../images/hello_creators_background_mobile.png';

import RadialGraphic from '../../images/radial-graphic.png';

const CreatorsData = {
  helloCreatorsBox: {
    primaryHeading: ["Hello,"],
    secondaryHeading: ["Target Creators!"],
    subTextPrimary: ["Your journey to build a brand partnership starts here"],
    backgroundImage: [
      CreatorsBackgroundDesktop,
      CreatorsBackgroundMobile,
    ],
  },

  featuresBox: {
    features: [
      {
        featureImage: ["https://gfc.target.com/partners/Share-content.png"],
        primaryHeading: ["Share content"],
        secondaryHeading: ["to learn, earn & grow"],
      },
      {
        featureImage: ["https://gfc.target.com/partners/Strengthen.png"],
        primaryHeading: ["Strengthen"],
        secondaryHeading: ["your community"],
      },
      {
        featureImage: ["https://gfc.target.com/partners/Get-access.png"],
        primaryHeading: ["Get access"],
        secondaryHeading: ["to your own storefronts"],
      },
    ],
  },

  joinCreatorsbox: {
    primaryHeading: ["Become a part of"],
    secondaryHeading: ["Target Creators"],
    backgroundImage: [RadialGraphic],
  },
};

export default CreatorsData;
