import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../Pages/Home'
import Faqs from '../Pages/faqs'
import Termsandcondtions from '../Pages/termsandconditions'
import Privacy from '../Pages/privayPolicy'
import Creators from '../Pages/Creators'
import NotFound from '../Pages/NotFound'

const routes = [
    {
        path: '/',
        component: Home
    },
    {
        path: '/not-found',
        component: NotFound
    },
    {
        path: '/faqs',
        component: Faqs
    },
    {
        path: '/termsandconditions',
        component: Termsandcondtions
    },
    {
        path: '/privacypolicy',
        component: Privacy
    },
    {
        path: '/creators',
        component: Creators
    },
    {
        path: '*',
        component: NotFound
    }
]

const Routes = () => {

    return (
        <Switch>
            {routes.map((route, index) => {
                return (
                    <Route key={index} exact {...route} />
                )
            })}
        </Switch>
    )
}

export default Routes