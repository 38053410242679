import React from 'react'
import { Helmet } from 'react-helmet'
import HeroWithImage from '../ComponentsList/HeroWithImage/HeroWithImage'
import LoveBox from '../ComponentsList/LoveBox/LoveBox'
import HomePage from '../SiteData/HomePage'
import BrandBanner from '../ComponentsList/BrandBanner/BrandBanner'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Info from '../ComponentsList/Info/Info'
import Stepbox from '../ComponentsList/Stepbox/Stepbox'




const Home = () => {
  const { hero, loveBox, brandBanner, stepbox, info } = HomePage
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Earn with Target Partners, Target’s official affiliates program</title>
        <meta name="robots" content="index, follow" />
        {/* Home page specific meta tags for targeted SEO, this overrides the main tags and won't if it's not included here */}
        <meta name='description' content='Whether you’re an influencer, blogger or affiliate earn up to 8% when you promote Target products with our Partners Program.' />
        {/* Any specific keywords added here will override the main keywords */}
        <meta name='keywords' content='Target Partners, Influencer, Affiliates, Partners Program, Affiliates Program' />
        {/*Global site tag (gtag.js) - Google Analytics*/}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-845PXTZEFH"></script>
        <script>{`
           window.dataLayer = window.dataLayer || [];
       function gtag(){dataLayer.push(arguments);}
       gtag('js', new Date());
       gtag('config', 'G-845PXTZEFH');
        `}
        </script> 

      </Helmet>
      <div>
        <HeroWithImage values={hero} desktop={desktop} video={true} />
        <LoveBox values={loveBox} desktop={desktop} />
        <Stepbox values={stepbox} desktop={desktop}/>
        <BrandBanner values={brandBanner} desktop={desktop} />
        <Info values={info} desktop={desktop} />
 </div>
    </>
  )
}

export default Home
