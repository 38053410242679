import React from 'react';
import { Helmet } from 'react-helmet';
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CreatorsData from '../SiteData/Creators';

import HelloCreatorsBox from '../ComponentsList/HelloCreatorsBox/HelloCreatorsBox';
import FeaturesBox from '../ComponentsList/FeaturesBox/FeaturesBox';
import JoinCreatorsBox from '../ComponentsList/JoinCreatorsBox/JoinCreatorsBox';


const Creators = () => {
  const { helloCreatorsBox, featuresBox, joinCreatorsbox } = CreatorsData;
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Earn with Target Partners, Target’s official affiliates program</title>
        <meta name="robots" content="index, follow" />
        {/* Home page specific meta tags for targeted SEO, this overrides the main tags and won't if it's not included here */}
        <meta name='description' content='Whether you’re an influencer, blogger or affiliate earn up to 8% when you promote Target products with our Partners Program.' />
        {/* Any specific keywords added here will override the main keywords */}
        <meta name='keywords' content='Target Partners, Influencer, Affiliates, Partners Program, Affiliates Program' />
        {/*Global site tag (gtag.js) - Google Analytics*/}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-845PXTZEFH"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-845PXTZEFH');
          `}
        </script> 
      </Helmet>
      <div>
        <HelloCreatorsBox values={helloCreatorsBox} desktop={desktop} />
        <FeaturesBox values={featuresBox} desktop={desktop} />
        <JoinCreatorsBox values={joinCreatorsbox} desktop={desktop} />
      </div>
    </>
  )
}

export default Creators;