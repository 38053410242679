const TermsPage = {
  breadcrumb: {
      tree: [
          { label: "Home", slug: "/" },
          { label: "Terms and Conditions", active: true },
      ],
  },
  textBoxOne: {
      heading: "Terms and Conditions",
      desc: `
      <p>These Target Affiliate Program Terms & Conditions (the “<b>Agreement</b>”) contain terms and conditions that apply to your participation as an Affiliate and/or Affiliate 
      Subnetwork (as such terms are defined herein) in Target’s Affiliate Program (the “<b>Target Affiliate Program</b>” or “<b>Program</b>”) through Target’s third-party vendor(s)
       (each, a “<b>Target Vendor</b>”), and the establishment of links from your website or other digital location, such as your social media page(s) (also referred to as “<b>your site</b>”) 
       to the following: </p>
      <ul>
      <li>target.com; and</li>
      <li>select mobile apps (collectively target.com and select mobile apps, and each, “<b>Target Domain</b>”), which may be extended in the future with notice.</li>
      </ul>
      <p>As used in this Agreement, “<b>we</b>”, “<b>us</b>” and “<b>our</b>” shall mean collectively, Target Corporation and/or its parents, subsidiaries and related entities (as applicable) 
      and Target Domains (collectively, “<b>Target</b>”), and “<b>you</b>” and “<b>your</b>” shall mean the Affiliate or Affiliate Subnetwork’s 
      participating in the Target Affiliate Program. </p>

      <p>By submitting your Target Affiliate Program application and participating in the Program, you are confirming that you have read and understand this Agreement, 
      you represent and warrant that you are lawfully able to enter into contracts, and you agree to be bound by, and will continue to comply with, this Agreement 
      throughout the full duration of your participation in the Program. Additionally, you acknowledge and agree that by submitting your application, which may include 
      clicking an acceptance box, the effective date of this Agreement will be the date on which you submit your application. </p>

      <p>Please be advised that this Agreement supplements any agreement, including any Insertion Order/Contract (defined below) that you have entered into with Target and/or the Target Vendor. 
      To the extent that any of the terms or conditions contained in this Agreement conflict with any other agreement terms and/or conditions, this Agreement shall control.  </p>
      <p> Target reserves the right to make changes to the Target Affiliate Program and this Agreement at any time, and such changes will be effective immediately upon either being provided to you via email
       or such other method we select in our sole discretion or otherwise being posted by the Target Vendor on any applicable platform used by the Target Vendor for administering the Program.</p>

      <p><b>MISUSE OF THE TARGET BRAND, PARTICIPATING IN PROHIBITED ACTIVITY AND/OR FAILURE TO COMPLY WITH THE TARGET AFFILIATE PROGRAM AND THE TERMS AND CONDITIONS OF THIS AGREEMENT MAY RESULT IN A LOSS, REDUCTION 
      AND/OR OFFSET OF REFERRAL FEES FROM SALES MADE THROUGH ANY AFFILIATE OR AFFILIATE SUBNETWORK THAT DOES NOT COMPLY WITH OUR PROGRAM TERMS OR TERMINATION OF YOUR PARTICIPATION IN THE TARGET AFFILIATE PROGRAM.  </b></p>

      <p><b>1.Defined Terms</b></p>
      <p><b>“Affiliate”</b> means a person or company that participates directly in Target’s Affiliate Program.</p> 

      <p><b>“Affiliate Subnetwork”</b> means a person or company that recruits Affiliates to participate in the Program or otherwise operates one or more 
      websites as sub-affiliates through one (1) application to participate in the Program. </p>

      <p><b>“Content”</b> means text links, terms and disclosures, promotional information (and all related information), marks, logos, images, videos, music, software, photographs, data, music, text,
       materials, application updates and other graphics supplied by Target to you in connection with an Offer (defined below) (including in connection with your development of any Affiliate Materials (defined below)). 
       All Content is owned or licensed property of Target or its suppliers or licensors and is protected by copyright, trademark, patent, or other proprietary rights.</p>

      <p><b>“Insertion Order/Contract”</b> means the documented instructions regarding category Referral Fees payout and policies, timeline and scheduling, which incorporate this Agreement by reference.</p>

      <p><b>“Merchandiser Datafeed”</b> means the datafeed that lists Target’s products and is available through the Target Vendor. The feed includes Target Catalog Identification Number (“<b>TCIN</b>”), price, product
       description, and other product information.</p>

      <p><b>“Net Price”</b> means the sale price listed online on our site (less any coupon or customer discount offered by Target). </p>

      <p><b>“Offer”</b> means communication and/or sale of Target Content, product or promotional event provided by the Target Vendor. Within Target’s Affiliate program, 
      an Offer is the Content that the Qualifying Link refers to.</p>

      <p><b>“Qualifying Action”</b> means an action, as defined by the Target rates set forth in the Insertion Order/Contract, which qualifies for a Referral Fee, or any action(s) that result in a 
      new record for that desired channel (e.g., creation of a new registry account).</p>

      <p><b>“Qualifying Link”</b> or <b>“Link”</b> means any type of banner or text link provided by Target to be displayed, distributed or played on your site or Affiliate Materials and can be tracked through Target’s Target Vendor and
      that can monitor clicks, sales, Applications, impressions, and other tracked activities achieved through the display or placement of such Link.   </p>

      <p><b>“Qualifying Product(s)”</b> means a product designated by Target in the Insertion Order/Contract that qualifies for a Referral Fee as defined by Target’s Referral Fees and the sale of which will result in the Affiliate 
      being paid a percentage of the sale as a Referral Fee. Not all products sold by Target qualify for Referral Fees.  Please refer to your Insertion Order/Contract for your specific rates and exclusions.</p>

      <p><b>“Referral Fees”</b> means the payment an Affiliate will receive for any Qualifying Action as defined by our referral fee statement or for selling Qualifying Products through Qualifying Links or Banners. See here 
      for Referral Fees: <a target="_blank" href="http://affiliate.target.com/?q=commissionRate">http://affiliate.target.com/?q=commissionRate</a></p>


      <p><b>“Target Vendor”</b> means any third-party online affiliate marketing network through which Affiliates or Affiliate Subnetworks may enter into engagements
       with Target (or other advertisers).</p>

      <p><b>2. Enrollment in the Program</b></p>

      <p>2.1. <i>Enrollment Process</i>. To begin the enrollment process and to become an Affiliate or Affiliate Subnetwork, you must submit a complete Affiliate application provided by Target or otherwise through the Target Vendor. We will evaluate 
      your application and will notify you of your acceptance or rejection for participation in the Program. We may reject your application if we determine (in our sole discretion) that your site or Affiliate Materials, as further defined in this Section:
       (i) are unsuitable for the Program for any reason, and/or (ii) if you are involved or allegedly involved in prohibited activities (as further described below).  </p>

      <p>If your application is not accepted, you may reapply to the Program at any time. If you are accepted into the Program, you will be able to participate in the Program subject to the terms and conditions of this Agreement as well as your agreement with
       the Target Vendor. Even if you are accepted to participate in the Program, if we determine (in our sole discretion), that your site or Affiliate Materials are unsuitable based on our criteria for the Program, we may terminate this Agreement and you may no longer link to Target Domains or maintain your Affiliate status. Our acceptance criteria are subject to change at any time without prior notice.</p>

      <p>2.2. <i>Unsuitable sites</i> and <i>Affiliate Materials</i>. A site or Affiliate Materials may be found unsuitable if they fall under any of the following conditions. Conditions are, but are not limited to, sites that:   </p>

      <ul>
        <li>Contain or promote materials that are sexually explicit or that could be deemed obscene, pornographic or excessively violent;  </li>
        <li>Promote violence or hate towards any persons or groups;</li>
        <li>Promote discrimination based on race, sex, age, religion, nationality, disability or sexual orientation; </li>
        <li>Promote illegal activities;</li>
        <li>Promote the sale or use of tobacco products, alcohol products or gambling;</li>
        <li>Violate any federal, state or local law (including privacy and “spam” laws);</li>
        <li>Contain material that, in our sole judgment, is defamatory, fraudulent or harassing;  </li>
        <li>Misrepresent themselves as a Target Domain by using the “look and feel” of or text from a Target Domain; </li>
        <li>Include “target” or variations or misspellings thereof in their domain names;  </li>
        <li>Otherwise violate intellectual property rights of Target or its affiliates;</li>
        <li>Infringe on Target’s or any third party’s intellectual property, publicity, privacy or other rights; </li>
        <li>Do not clearly state an online privacy policy to their visitors;</li>
        <li>Require a username and password to access; </li>
        <li>Are not US based or primarily serve or market to audiences not in the US;</li>
        <li>Are child-directed or have knowledge that personal information of children under 13 is being collected, used or disclosed;</li>
        <li>Are unable to direct a reasonable amount of traffic by way of sales volume, clicks and page views to a Target Domain; </li>
        <li>Excessively use pop-ups;</li>
        <li>Require downloads and/or knowingly download software to visitors’ computers; </li>
        <li>Link to or provide a portion of their commission or Affiliate benefits to sites or organizations that violate any or the above criteria;  </li>
        <li>Are under construction; </li>
        <li>Do not have a clear focus on e-commerce (sites that do not sell products or feature merchants who will not submit a business plan describing
           how they will drive revenue for Target.com or m.Target.com);</li>
        <li>Contain any viruses, Trojan horses, worms, time bombs, cancelbots, or other computer programming routines that are intended to damage,
         interfere with, surreptitiously intercept or expropriate any system, data, or personal information;</li>
        <li>Contain software or use technology that attempts to intercept, divert or redirect Internet traffic to or from any other website, or that potentially 
        enables the diversion of affiliate commissions from another website; or</li>
        <li>Are otherwise considered, in Target’s sole judgment, offensive or inappropriate. </li>
      </ul>


      <p>2.3. <i>Prohibited Activities.</i> Target may elect to reject your site if Target determines in its sole discretion that your site contains or otherwise
       involves prohibited activity.  As a condition to your acceptance and participation in this Program, you agree that you will not undertake or engage in the 
       following practices, and any violation of this Section shall be deemed a material breach of this Agreement and may result in immediate termination from 
       the Program:</p>
      <ul>
          <li>
          Use the Target name, or any variation thereof, in any manner not expressly authorized by this Agreement or authorized by Target in writing; </li>

          <li>Use or otherwise incorporate the word “Target” or variations or misspellings thereof in the domain name(s) of your site(s) or 
          Affiliate Materials, or in hidden text or source code;</li>

          <li>Bid on Target brand keywords, variations or misspellings, or keyword strings (e.g., ‘target’, ‘Target.com’, ‘target.clearance’, ‘target weekly ad’,
             ‘target store’, or ‘target coupon’) in search engines or content networks;</li>

          <li>Use Target brand keywords, keyword strings or variations or misspellings thereof in the headline,
           display URL or description of pay-per-click ads or ads or content network ads;</li>

          <li>Use your Qualifying Links as the destination URL in any paid advertisement. This is also known as direct-linking.</li>

          <li>Use of the content network resulting in being ranked on Target brand keywords;</li>

          <li>Engineer your site or Affiliate Materials in such a manner that pulls internet traffic, for Target brand keywords,
           away from Target.com via natural search optimization. Techniques include:
          <ul>
            <li>Using Target or Target brand keywords in the title tag, domain name, other meta tags, or
             other on-page factors that influence rankings;</li>

            <li>Use Target’s Merchandiser Datafeed on a site other than the site we have approved pursuant to the Program;</li>

            <li>Distribute Target’s Merchandiser Datafeed to a third party shopping site (e.g., Yahoo!, MSN, eBay, Google, AOL, Amazon)
             regardless of whether or not we are already advertising products on that site;</li>

              <li>Engage in cookie stuffing, forced clicks, or the use of popups/popunders or false or 
              misleading links on your site or Affiliate Materials;</li>

              <li>Use redirects to indicate the referring site was something it actually is;</li>

              <li>Attempt to modify or alter our site in any way;</li>

              <li>Make any representations, either express or implied, or create an appearance that a visitor to your site
               is visiting our site (e.g., “framing” Target.com or m.Target.com) without our prior written approval;</li>

              <li>“Scrape” or “spider” Target.com or m.Target.com or any other Target site or application for Content (such as images, logos or text) 
              unless you have Target’s express written consent; or</li>

              <li>Post your Qualifying Links on any of Target-owned platforms or social media accounts.</li>

          </ul>
          </li>
      </ul>
      <p>


      2.4. <i>Target Entities Exemption</i>. Employees, family members of employees, or any entities directly or indirectly owned or
       controlled by employees or family members of Target Corporation or its subsidiaries, suppliers of Target and advertising, promotion
        and fulfillment agencies of Target are not permitted to be Affiliates.
      </p>

      <p>
      All domains used to post Links must be listed in your “approved affiliate” profile.  You will provide us with information on how you are promoting Target,
       including, without limitation, copies of Affiliate Materials, within forty-eight (48) hours of our request.
      </p>

      <p>
      <b>3. Qualifying Links</b>
      </p>
      <p>3.1. <i>Links Usage</i>. If you are accepted into the Program, we will make available to you Qualifying Links that, subject to the terms and conditions of this Agreement, 
      you may display as often and in as many areas on your site as you desire. </p>

      <p>3.2. <i>Obtaining Links, Banners and Images</i>. All Qualifying Links that you will use in the Program will be provided to you by us or the Target Vendor, Target Affiliates 
      communication, or by other means selected by us. If we provide you with any Content (e.g., the Target logo), you will display Content in its complete and unaltered form, and 
      you will substitute such Content with any new Content provided by us from time to time throughout the term of this Agreement.  </p>

      <p>3.3. <i>Link Tracking</i>. The Qualifying Link contains site-specific Target Vendor tracking information that identifies your site as a member of the Program and will establish 
      a link from your site to Target’s. Only valid Qualifying Links obtained through the Target Vendor (or via an approved process if hosted outside of the Target Vendor’s platform) 
      will be tracked for purposes of determining Referral Fees that you may be eligible to receive on sales of Qualifying Products or Qualifying Action(s) generated through your site.</p>

      <p>3.4. <i>Link Manipulation</i>. Qualifying Links may not be manipulated in any way, including: (a) redirecting links to hide or manipulate their original source; or (b) Direct-linking,
       e.g. using your Qualifying Links as the destination URL in any paid advertisement.</p>

      <p>3.5. <i>Link and Offer Terms</i>. The terms of an Offer (e.g. special instructions, exceptions, dates valid) will be posted on the Target Vendor’s site or otherwise communicated to you. 
      In the event of any inconsistency between the communicated terms of the specific Offer and the terms of this Agreement, the terms of the Offer shall govern.</p>


      <p>3.6. <i>Misuse of Offer</i>.Posting or releasing any information about how to work around the requirements of a coupon/promotion will result
      in immediate removal and termination from the Program.</p>

      <p>3.7. <i>Early Promotion</i>. You may not post an Offer prior to the start and end dates indicated in the Offer terms without written consent by Target. Early promotion without 
      permission may result in immediate removal and termination from the Program.</p>

      <p>3.8. <i>Offer Discontinuation</i>. At any time prior to you providing a Qualifying Link, we may with or without notice: (a) change, suspend or discontinue any aspect of an Offer; or 
      (b) remove, alter, or modify any graphic or banner ad provided to you pursuant to an Offer. You agree to promptly implement any request from Target to remove, alter or modify any graphic 
      or banner ad posted on your site.</p>


      <p>3.9 Paid Media Placement. You may not use a Qualifying Link provided through the Affiliate Program on any paid placements 
      made in partnership with another team at Target (i.e., Target marketing, Target Media Network or any other Target agency that is purchasing on our behalf).</p>


      <p><b>4. Promotion Codes, Coupons and Special Offers</b></p>


      <p>4.1. <i>Promotion Code Source</i>. You are only allowed to use Target promotion codes that are communicated specifically to you via the Target Vendor on 
      behalf of Target. If you use Target promotion codes from other sources (e.g., Target e-mails or other partner or non-partner communications), we may recover any Referral Fee payments resulting from the use of those codes.</p>

      <p>4.2. <i>Offer Visibility</i>. Coupons must be displayed in their entirety with the full Offer, Offer exclusions or disclaimer text, valid expiration date and code. </p>

      <p>4.3. <i>Special Offers</i>. From time to time, we may post on the Target Vendor specific Offers that pay Affiliates a specified Referral Fee for the sale of Qualifying Products. These Offers may not be listed on the Affiliates
       Fees site and may run for a short period of time.</b></p>

      <p><b>5. Requirements and Disclosures.</b></p>

      <p>5.1. Affiliate Link Disclosures. As a condition of your participation in the Program, you must comply with all laws, rules and regulations applicable to your participation in the Program, including for example, the requirement to make proper and appropriate disclosures in 
      compliance with the Endorsement and Testimonial Guidelines published by the United States Federal Trade Commission (available at  
          <a href="http://www.ftc.gov/opa/2009/10/endortest.shtm" target="_blank"> http://www.ftc.gov/opa/2009/10/endortest.shtm</a>) and in compliance with the Native Ad Guides (available at 
          <a href="https://www.ftc.gov/tips-advice/business-center/guidance/native-advertising-guide-businesses" target="_blank">https://www.ftc.gov/tips-advice/business-center/guidance/native-advertising-guide-businesses</a>), and the FTC's “Dot Com Disclosures”
             Guidelines (available at: <a href="http://www.ftc.gov/os/2013/03/130312dotcomdisclosures.pdf" target="_blank">http://www.ftc.gov/os/2013/03/130312dotcomdisclosures.pdf</a>) (collectively, the “<b>FTC Guidelines</b>”). </p>
      <ul>
          <li>
          In addition to other measures that you take to comply with such FTC Guidelines, you must clearly and conspicuously state the following (or something very similar considering space and 
            format available) at the beginning of the messaging that contains a reference or link to Target:
          <ul>
            <li>“<b>[INSERT YOUR NAME]</b> is a participant in the Target Affiliate Marketing Program, an affiliate advertising and marketing program that pays commissions to affiliates, like me, that advertise and link to  <b>[INSERT APPLICABLE TARGET 
            DOMAIN NAME]</b> if you buy products from Target through my link.”; or</li>

            <li>“<b>[Product/Service]</b> purchase through this link [may be/is] commission eligible.”</li>
          </ul>
          </li>
          <li>
          To the extent that your messaging will be posted on social media platform(s) where space for inclusion for the disclosure is limited (e.g., Twitter), you may, as an 
          alternative to the prior sentence, disclose by including both of the following hashtags at the beginning (first two (2) lines) of the post and, if the link is in a 
          photo or video, next to the link itself:     <ul>
         <li><b>o	#PaidLink </b> and <b> #TargetPartner</b></li>
          </ul>
          </li>
      </ul>
      <p>In compliance with the FTC Guidelines, as noted above, you must clearly and conspicuously identify yourself as an Affiliate on all pages and social media posts where a Link is
       included by you or where you otherwise advertise or promote the Products. You shall include a clear and conspicuous disclosure within any and all pages, blog/posts, or social media
        posts where Links for our Program are posted and where a reader or consumer may not understand that the link is a paid advertisement. You understand and agree that any messaging in 
        contravention of the FTC Guidelines may result in your immediate termination and removal from the Program. In addition, you must include a disclosure that complies with FTC Guidelines
         where: (a) disclosures must be made as close as possible to the claims; (b) disclosures must be made on each page containing a sponsored Link or reference to Target (a single statement
           on your site, or a separate page with your general disclosure statement is not an adequate disclosure); (c) disclosures should be placed above the fold/scroll; scrolling should not be
            necessary to find the disclosure; and (d) social media posts must include appropriate hashtags as indicated above. Here are some examples:</p>
    
              
              <ul>
                  <li><u> Videos & Live Conversations.</u> Include it in writing (if written text is not possible, verbally) early on in the video or live conversation, or on a title card. 
                  For videos and conversations or streaming (e.g., Twitch or Instagram Live) more than 2 minutes long, or a series of posts (e.g., Snapchat or Instagram Stories) repeat the 
                  disclosure periodically throughout.</li>

                  <li><u>Photos, Images & Graphics.</u> Include it on the image or prominently include it in the post or Tweet near the image.</li>

                  <li><u>Written Posts.</u> Include it towards the beginning, above-the-fold, before a viewer has to click “read more.” (e.g., on Instagram this is within the first 2 lines).
                   If included with other hashtags, include the disclosure first in the string.</li>

                  <li> Ensure your disclosure is included in a manner so it travels with your content when shared. Refer to branded content tools on applicable social media platforms on
                   how to tag Target in your post and other information. FYI - using the platform’s tools may not be enough in order to follow these guidelines. For example, if you use
                    Instagram’s tool that indicates a post is sponsored by a brand partner, such indication may not show when sharing such post to Twitter and you may need to add a 
                    different disclosure using Twitter.
                  </li>
              </ul>



      <p>The FTC Guidelines, including hashtags and disclaimer requirements, are subject to change over time and it’s your responsibility to review and comply with current guidelines as policies
       may change over time.  <b>Please regularly review the FTC Guidelines for updates</b>.  If you have any questions about your legal obligations to comply with the FTC Guidelines, please consult 
       with your legal representative. </p>

      <p>5.2. <i>SEM and SEO tactics</i>. You are prohibited from engineering your site or other Affiliate Materials in a manner that ranks for Target or Target owned brand keywords and product
       variants (e.g., “Target”, Threshold”, “Target furniture”, Threshold furniture”, etc.) via search engine optimization, including misspellings (e.g., “Targt”, “Thrshold Furniture”, etc.). 
        You are prohibited from executing SEM tactics that include, but are not limited to, the following: bidding on Target or Target owned brands or misspellings of the brands; using Target or
         Target owned brands in a headline; display URL or description of pay-per-click ads. In addition, you agree to add Target brand terms and phrases as a negative to your campaign to avoid 
         broad/phrase matching.</p>

      <p>5.3. <i>Site and Post Auditing</i>. We reserve the right to audit your site or other Affiliate Materials without notice to ensure compliance with this Agreement and disclosures.  We reserve the right to
       remove you from the Program if we determine you are not in compliance. You agree to provide Target with unrestricted access to your site and other Affiliate Materials for such purpose(s).</p>

      <p>5.4. <i>Target Site Modification and Asset Management</i>. You are prohibited from altering or modifying Target Domains or sites in any way. In addition, you are prohibited from scraping or crawling 
      Target’s sites or social media for Content such as images, logos, or text, unless you have Target’s express written consent.
      </p>

      <p>5.5. <i>Merchandiser Datafeed</i>. Target will provide a Merchandiser Datafeed via Target Vendor that can be used to supply our Content to your site. You are prohibited from using Target’s Merchandiser Datafeed on a site other than the site you have been approved for by us. You are prohibited from distributing Target’s Merchandiser Datafeed to a third party shopping site 
      (e.g., Ebay, Google, Amazon, Walmart, or other third-party marketplaces), regardless of whether or not we are already advertising our products on that site. In addition you are prohibited from utilizing the Merchandiser Datafeed to advertise via Shopping Ads on search engines.  </p>

      <p>5.6. <i>Use Restrictions of Merchant APIs</i>. Use of content consisting of application programming interface and information related thereto must be expressly permitted from Target and otherwise is expressly prohibited. You shall not access or otherwise use the 
      API or related information for any purposes not approved by Target.</p>

      <p>
      <b>6. Communication and Branding</b>
      </p>

      <p>6.1. <i>Press Releases and other Marketing Material</i>. You hereby agree that you will not make any references to Target or any individual Target employee, or issue any press release(s) or make any other public communication (written or verbal) regarding this Agreement, your use of Target’s Content, 
      or your participation in the Program without having first submitting such material to us and receiving our prior written approval which we may withhold in our sole and absolute discretion.</p>

      <p>6.2. <i>Social Media</i>. You are prohibited from posting Links to Target-owned platforms or social media pages (including, but not limited to, Facebook, Twitter, Pinterest, Instagram, etc.). According to the FTC Guidelines, social media posts on your own social accounts must include appropriate hashtags. Hashtags and disclaimers are subject to change over time and it’s 
      your responsibility to review and comply with current FTC Guidelines as policies may change over time.</p>

      <p>6.3. <i>Charitable Endorsements</i>. If your site or Affiliate Materials promises to donate any portion of its Referral Fees to any school, foundation or other charitable organization, you may not state or imply that Target endorses such activities or is responsible in any way for the
       inclusion and donation of funds to any of the schools, foundations or charities associated with your activities. In addition, it is recommended that you seek tax and legal advice from your own counsel as commercial co-venture laws apply and must be complied with in order to legally conduct such a campaign.</p>

      <p>6.4. <i>Email</i>. Any emails must be sent on your behalf and must not state or imply that the email is being sent on behalf of Target. Any email containing Target Content or branding not sourced from the Target Vendor must be approved by Target prior to sending.</p>

      <p>6.5. <i>Communication with Target</i>. If we ask you for clarification or more information on any orders, applications, or clicks that we suspect may be in violation of our terms and conditions, we expect that you will respond in a timely and honest manner.
       Violations include (a) being intentionally vague or lying; (b) being unresponsive for a reasonable time period after multiple attempts to reach you; and/or (c) being unable to substantiate or validate your source of traffic to Target Domains.</p>

      <p><b>7. Development, Operation & Maintenance of Your Site and Affiliate Materials</b></p>


      <p>
      7.1. <i>Responsibilities.</i> You are solely responsible for the development, operation and maintenance of your site and for all the content that appears on your site and Affiliate Materials. Your responsibilities include the following, in addition to those otherwise provided in this Agreement:
     

      <ul>
        <li>The accuracy, timeliness and appropriateness of content posted on your site and Affiliate Materials (including, among other things, all Target Content and product-related materials);</li>
        <li>Ensuring that your site and Affiliate Materials do not employ the use of any type of software download or technology that intercepts or re-directs traffic or referral fees to or from any website without the written consent of Target;</li>
        <li>Ensuring that materials posted on your site do not violate or infringe upon the rights of any third party (e.g. including copyrights, trademarks, privacy, or other personal or proprietary rights);</li>
        <li>Notifying Target and the Target Vendor of any malfunctioning of the Qualifying Links or other problems with your participation in the Program in accordance with the terms of the Offer and this Agreement; and</li>
        <li>Notifying Target prior to implementing or removing any cashback offerings, or adding any Browser Extension (e.g., “Chrome”) or other browser functionality. Such changes may affect your Referral Fees (as further described in Section 9).</li>
      </ul>
      </p>

      <p>7.2. <i>Affiliate Subnetworks</i>. Affiliate Subnetworks may be permitted to participate in the Program with Target’s prior written approval, and at its sole’s discretion; provided, however, that Affiliate Subnetworks agree to comply with the additional 
      terms and conditions applicable to Affiliate Subnetworks attached hereto as Exhibit “A” and incorporated herein by this reference.</p>

      <p><b>8. Order Processing, Tracking, and Reporting </b></p>

      <p>8.1. <i>Processing Responsibility</i>. Target will process orders and applications submitted by customers who follow Qualifying Links from your site or Affiliate Materials to a Target Domain in accordance with our internal ordering processes. We will be responsible for all order and application processing and fulfillment.  </p>

      <p>8.2. <i>Order Requirements</i>. We reserve the right to reject customer’s orders or applications for any reason, including, without limitation, those that do not comply with any reasonable requirements that we periodically may establish, including, but not limited to, those in Target.com’s terms and conditions, viewable here:  <a href="http://www.target.com/c/terms-conditions/-/N-4sr7l"
      target="_blank">http://www.target.com/c/terms-conditions/-/N-4sr7l</a>.</p>


      <p>8.3. <i>Order Tracking</i>. We will use commercially reasonable efforts to track sales to customers who purchase products from Target Domains and other Qualifying Actions to your site or Affiliate Materials using Qualifying Links that you will generate using the Target Vendor’s technology. To permit accurate tracking, reporting and fee accrual, you must ensure that the  Links between your site and the
       Target Domain are properly formatted. Target will not be responsible for improperly formatted Links regardless of whether you have made amendments to the code or not. Target is unable to track or provide credit for sales and Qualifying Actions from customers that are referred to us with browsers that do not have their cookies setting enabled.  Our sales records with respect thereto will be final and binding with respect to matters relating to the Referral Fees that you earn and are paid and such books and records are not subject to audit by you.   </p>


      <p>8.4. <i>Reporting</i>. Reports summarizing this activity will be available to you through the Target Vendor. The form, content and frequency of the reports are limited to those reports and capabilities available through the Target Vendor and may vary from time to time in our and/or the Target Vendor’s reasonable discretion. Target is not responsible for any changes that the Target Vendor may make in reporting format or timing or in the types of reports available.   </p>

      <p>8.5. <i>Order Inquiry Window</i>. If you believe an action did not track, you may create an action inquiry to provide the missing order details to Target for review. By passing an order ID to Target, you are asking Target to verify the order ID and credit your account
       if the action failed to track. This process is limited to actions which have occurred within the action locking period (i.e., when commissionable actions become locked) and orders falling outside of such locking period will not be considered valid.</p>

      <p><b>9. Referral Fees</b></p>

      <p>9.1. <i>Referral Fee Generation</i>.You are only eligible to earn Referral Fees on sales of Qualifying Products or Qualifying Actions occurring during the term of this Agreement. You will earn Referral Fees based on the Net Price of Qualifying Products and/or on net new number of Qualifying Actions, according to the current Insertion Order/Contract (published rates if no Insertion Order/Contract exists) 
      established by us and communicated to you upon acceptance of the Agreement. For a Qualifying Product or Action(s) to generate a Referral Fee, the customer must (a) use a browser that has its cookies setting enabled; and (b) follow a Qualifying Link.  </p>

      <p>9.2. <i>Qualifying Products and Actions</i>. Some items, brands, products, categories, or actions may not be eligible for Referral Fees. Please refer to your Insertion Order/Contract for your specific rates and exclusions. Standard rates are published on affiliate.target.com. </p>

      <p>9.3. <i>Site Re-Entering</i>. We will pay a Referral Fee if the customer re-enters during the cookie window specified in the Offer through the Target Vendor UNLESS the customer re-enters through another Affiliate’s Qualifying Link or from another marketing link (i.e. paid search, social, email, or other marketing). </p>

      <p>9.4. <i>Referral Fee Payment</i>. Referrals are only considered for payment according to the dates and timelines detailed in the current Insertion Order/Contract. All determinations of Qualifying Links and whether a Referral Fee is payable will be made by the Target Vendor and will be final and binding.</p>

      <p>9.5. <i>Returns</i>. If a customer returns a Qualifying Product that generated a Referral Fee, we will deduct the corresponding Referral Fee from your monthly payment. Referral Fees earned through the date of termination will remain payable only if the related orders are not canceled or returned by a customer.</p>

      <p>9.6. <i>Reporting Interruptions</i>. From time to time, the Target Vendor tracking for purposes of Referral Fees may be interrupted by site releases or other activity initiated by or on behalf of Target. If the reporting of Affiliate conversion data to our Target Vendors is hindered due to such interruption, Target will use commercially reasonable efforts to provide to the Target Vendors such missing
       or interrupted affiliate conversion data. If such data cannot be provided through Target’s commercially reasonable efforts, Referral Fees for the interrupted time period will be calculated based on historical averages for similar time periods. </p>

      <p>9.7. <i>Payment of Referral Fees</i>. Target has established a relationship with a Target Vendor for the payment of all commissions and bonuses.  All Affiliates must enroll in the Target Vendor’s payment program which is operated by an independent third-party payment processor.  You authorize Target, or an independent payment processor acting on its behalf, to establish an account on your behalf, or
       in the case of an Affiliate enrolled as a business entity on behalf of the business entity, and to deposit monies owed to you into the account.  Should any funds be deposited erroneously into your account, you authorize Target to direct the payment processor to debit or credit your account as necessary to correct any errors.   </p>

      <p>9.8. <i>Conditions on Payment of Referral Fees</i>. .  Prior to receiving Referral Fees from the Target Vendor, you may be required to fill out and submit a W-9 and send it back to us or them in the method requested.  Only after your W-9 has been received will you be eligible to receive Referral Fee payments from the Target Vendor.  Subject to compliance with Section 2 above, while this Agreement 
      is in place between You and us, our Target Vendor will pay you any Referral Fees earned by you as specified in the Insertion Order/Contract (if unspecified, on a monthly basis) and only after our actual receipt of the payment from the Qualifying Product purchaser.  If you terminate this Agreement, we will pay you any undisputed Referral Fees earned by you as specified in the Insertion Order/Contract 
      (if unspecified, within sixty (60) days of the date your written notice of termination is received by us).  Notwithstanding the foregoing, we reserve the right to extend the time in which we send out any Referral Fees if we offer the ability to obtain a refund from the purchase of a Qualifying Product until sixty (60) days after the refund period expires.  Subject to the foregoing, we will pay the 
      Referral Fees as specified in your Insertion Order/Contract (if unspecified, we will either deposit your Referral Fees in your designated account or send you a check for any Referral Fees you have earned via mail to the address we have on file for you).  If returns, refunds or other events require recalculation of Referral Fees for a period for which Referral Fees have already been paid, we will 
      offset from Referral Fees payable in succeeding periods by the amount which Referral Fees are reduced by such recalculation.  We may maintain a reasonable reserve against such deductions.  If there are no subsequent Referral Fees payable, we will send you an invoice for reimbursement of the overpaid Referral Fees, and you agree to pay any such invoice within fifteen (15) days of the date of such invoice. 
      You will be solely responsible for reporting and paying all income and other related taxes associated with the Referral Fees paid to you and for all compensation benefits and taxes to or for your employees and any third parties engaged by you in connection with your participation in the Program.  If you are an individual, we may deduct from your Referral Fees any unemployment insurance payments or any other
       payments required by law for us to withhold or pay in connection with your participation in a Program.  You will defend, indemnify and hold us harmless in all respects with respect to any taxes, penalties, damages, costs and expenses relating to our payments made to you hereunder without withholding of taxes.  We reserve the right in our sole and absolute discretion to withhold payment of any Referral Fees 
       owed to you if we have any suspicion or actual evidence of electronic or non-electronic tampering with any portion of the Program, or if a computer virus, bug, unauthorized intervention, fraud, technical difficulties, or failures compromise or corrupt or affect the administration, integrity or security of the Program by you or a third party.</p>
      
      <p><b>10. Reversals</b></p>

      <p>10.1. <i>Reselling</i>. We have the right to cancel or withhold Referral Fees for any Qualifying Product(s) or Actions we suspect to be made with intent to resell and/or produce sales or actions that are made through fraudulent or illegal means.</p>
      
      <p>10.2. <i>Modifications</i>. We reserve the right to reverse orders or Qualifying Actions due to cancellations, duplicate tracking, returns, disputed charges, and Program violations as outlined in this Agreement.  </p>
     
      <p>10.3. <i>Violations</i>. If a violation of this Agreement occurs we reserve the right to reverse orders, set your Referral Fee Rate to 0% and/or suspend you from the Program for the period or orders in question.  </p>
     
      <p><b>11. Customer Policies and Pricing</b></p>
      
      <p>11.1. <i>Customer Status</i>. Customers who buy products through the Program will be deemed to be customers of Target, without affecting their status as your customer. </p>
      
      <p>11.2. <i>Customer Policies</i>. All of our rules, policies and operating procedures concerning customer orders, customer service and sales will apply to those customers when using the Target Domain. We may change our policies and operating procedures at any time in our sole discretion.   </p>
      
      <p>11.3. <i>Product Price</i>. We will determine the prices to be charged for products sold under the Program in accordance with our own pricing policies. Product prices and availability may vary from time to time. Due to occasional changes in price, you may not include price information in your site or Affiliate Materials unless it is used as part of Target’s Merchandiser Datafeed from the Target Vendor. Target will use commercially reasonable efforts to present accurate information, but we cannot guarantee the availability or price of any particular product or the error-free or uninterrupted operation of the Target Domain or the Target Vendor’s platform.</p>
     
      <p><b>12. Term and Termination</b></p>

      <p>12.1. <i>Program Term</i>. The term of this Agreement will begin upon our acceptance of your Program application and will end when terminated by either party. Either party may terminate this Agreement at any time by providing written notice (email acceptable) to the other party of its intent to terminate, provided that you must send your written notice of termination to us at <a href="mailto:targetpartners@target.com" target="_blank">targetpartners@target.com</a>, <a href="mailto:targetcreators@target.com" target="_blank">targetcreators@target.com</a>.In addition, Target may terminate this Agreement immediately if you materially breach or violate any terms or conditions of this Agreement, or if Target determines, in our sole discretion, that there are technical or operational issues (e.g., interruptions caused by or shifts in online/Internet technology) that adversely affect the implementation of the Program, or the orders/referrals 
      were obtained fraudulently or through misrepresentation, in which case we reserve the right to withhold payment of associated Referral Fees pending an investigation of the suspected fraud or misrepresentation. Termination of this Agreement shall also terminate any outstanding Offer. All rights to payment, causes of action and any provisions that by their terms are intended to survive termination, shall survive termination of this Agreement.</p>

      <p>12.2. <i>Offer Term</i>. Either party may terminate an Offer at any time by deleting its acceptance of the Offer through the Target Vendor. Termination of a specific Offer shall not be deemed to terminate any other Offers.</p>
      
      <p>12.3. <i>Termination Requirements</i>. Upon termination of this Agreement, you will immediately cease use of, and remove from your site and Affiliate Materials, all Links to the Target Domain and all Target-related Content. You agree to return to Target any 
      and all documents or other media embodying Target’s image, marks, or other intellectual property, and you agree that you will not (a) use the Target name, or any variation thereof, in any manner not expressly authorized by us; (b) create, publish or distribute 
      any materials, written or verbal, that make reference to Target or any individual within Target, without first submitting such material to us and receiving our prior written consent, which we may withhold in our sole discretion; or (c) use the Target name to disparage 
      Target, its products or services, or in a manner that, in our sole judgment, may diminish or otherwise damage the goodwill in our name, Licensed Materials and/or our products and services.</p>

      <p><b>13. Licenses and Use of the Target Logos and Trademarks</b></p>

      <p>13.1. <i>Trademark Usage</i>. Provided you are an Affiliate in good standing (i.e., not in breach of this Agreement), we grant you, during the time you are an Affiliate, a limited, non-exclusive, non-transferable, non-assignable, revocable right and license to: (a) access our Target
       Domain Content through the Qualifying Links solely in accordance with the terms of this Agreement and (b) solely in connection with such Links, to use our logos, trade names, trademarks and similar identifying material designated in the Offer (collectively, the “<b>Licensed Materials</b>”), 
       only as provided to you through the Target Vendor and solely for the purpose of generating the sale of Target products from your site and in connection with your Affiliate Materials. You agree to the limitations below:
        <ul>
        <li>Each Target trademark must appear by itself and must be surrounded by sufficient empty space on all sides in order to avoid unintended associations with any other objects (including, without limitation, type, photography, borders and edges).</li>
        <li>You must include the following notice on any materials you create that include the Target logo: The Bullseye Design and Target are registered trademarks of Target Brands, Inc.</i></li>
        </ul>
      </p>

      <p>13.2. <i>Intellectual Property Rights</i>. You acknowledge that this Agreement does not provide you with any intellectual property rights in the Licensed Materials other than the limited rights contained herein. We reserve all of our rights in the Licensed Materials and all of our
       other proprietary rights. You may not sublicense, assign or transfer any such licenses for the use of the Licensed Materials, and any attempt at such sublicense, assignment or transfer is void. We may terminate your license to use the Licensed Materials for any reason at any time in 
       our sole and absolute discretion.</p>
     
      <p>13.3. <i>Licensed Material Usage</i>. You shall not make any specific use of any Licensed Materials for purposes other than generating the sale of Target products from your site without first submitting a sample of such use to us and obtaining our prior written approval, 
      which we may withhold in our sole and absolute discretion. Without limiting the foregoing, you may not use any Licensed Materials in direct mail or email without first submitting a sample of such use to us and obtaining our prior written approval, which we may withhold in our 
      sole and absolute discretion.</p>
     
      <p>You may use the Licensed Materials only for purposes expressly authorized by us and follow the below limitations:</p>
      <ul>
          <li>You may only include Licensed Materials in emails and newsletters that are approved in advance by us and are fully compliant with all applicable laws and regulations, including the CAN-SPAM Act.
          You may not alter, modify, or change the Licensed Materials in any way; for example, you may not change the proportion, color, or font of any trademark.
          </li>
          <li>You may not display the Licensed Materials in any manner that implies our sponsorship or endorsement of your products, services or site outside of your involvement in the Program.</li>

          <li>You may not use the Licensed Materials to disparage Target, its products or services, or in a manner that, in our sole judgment, may diminish or otherwise 
          damage the goodwill in our name and the Licensed Materials.</li>

          <li>You may not use the Licensed Materials as a feature or design element of any other logo.</li>

          <li>Your use of the Target name or logo shall be in a manner that is clearly less prominent than that of
           your products, trademarks, logos and/or site name.</li>

          <li>You may not copy any image on our site except for those specifically provided to you under this Agreement.</li>
      </ul>
     
      <p>13.4. <i>Licensed Materials Guidelines</i>. You understand and agree that Target Brands, Inc., a related company of Target, owns the Licensed Materials and may be a necessary party in any undertaking to enforce this Agreement. We reserve the right in our sole discretion to modify
       these guidelines at any time with notice and the right to take action against and use that does not conform to these guidelines. The determination of whether you are using the Licensed Materials, your page, Affiliate Materials or any other content or materials related to this Agreement
        in the best interest of Target is left to our sole and absolute discretion.  We may revoke your license to the Licensed Materials at any time, upon notice to you, at which time you agree to immediately cease using the Licensed Materials.  We may from time to time e-mail you with any 
        changes or updates to the Licensed Materials, the Program and/or our related products and services and you must promptly comply with any directions from us in connection therewith.  If you have questions about any of these restrictions, please contact us at  <a href="mailto:targetpartners@target.com">targetpartners@target.com</a>, <a href="mailto:targetcreators@target.com">targetcreators@target.com</a> 
        for clarification and to obtain our written approval for using a tool or other marketing device in connection with your marketing activities for the Program.  Any approval by us of tour tools or other activities will not, under any circumstances, void, nullify or constitute a waiver of your indemnification obligations hereunder, 
        the independent contractor nature of your engagement or of any of our rights.</p>
           
           <p>13.5. <i>Affiliate License to Affiliate Materials</i>. You grant us a non-exclusive, royalty-free, unrestricted, unconditional, unlimited, worldwide right and license (with right to sublicense) to use your approved names, titles, logos, trademarks, trade names, and service marks, copyrights, and any other materials created or
            used by You (which must be approved by us in writing) in connection with the Program (collectively, the “<b>Affiliate Materials</b>”) to advertise, market, promote and publicize in any manner the Program, your participation in the Program and/or to promote Target or our product(s).  Target will not be required to use the Affiliate
             Materials or to advertise, market, promote or publicize your affiliation with the Program.  You represent and warrant that the Affiliate Materials, your page and any other materials created or used by you in connection with the Program will not contain anything that, in Target’s sole judgment, violates any law, regulation or
              ordinance or third party rights, is considered to be defamatory or constitute libel or slander, may be inconsistent with Target’s public image, may be in bad taste, are indecent or in Target’s opinion otherwise objectionable, or may tend to bring disparagement, ridicule, or scorn upon Target or any affiliated and/or subsidiary 
              companies.  You are responsible for ensuring that the Affiliate Materials and any other materials posted on Your page do not violate, infringe or misappropriate copyrights, right of publicity, trademark rights or other rights of us or any third party.  You must have express written permission to use another party’s intellectual,
               proprietary or contractual rights, or to use another’s name, portrait, voice or likeness and you agree to provide such written permission to us promptly upon request.  You will be solely responsible for the development, operation and maintenance of your page, for all materials that appear on your page, and for all marketing and 
               promotion you conduct on your page and in public.</p>

      <p><b>14. Disclaimers</b></p>
     
      <p>WE MAKE NO EXPRESS OR IMPLIED WARRANTIES OR REPRESENTATIONS WITH RESPECT TO THE PROGRAM, THE LICENSED MATERIALS, OR ANY PRODUCTS SOLD THROUGH THE PROGRAM (INCLUDING, WITHOUT LIMITATION, WARRANTIES OF FITNESS, 
        MERCHANTABILITY, NONINFRINGEMENT, OR ANY IMPLIED WARRANTIES ARISING OUT OF COURSE OF PERFORMANCE, DEALING OR TRADE USAGE). IN ADDITION, WE MAKE NO REPRESENTATION THAT THE OPERATION OF ANY TARGET DOMAIN WILL BE UNINTERRUPTED OR ERROR FREE, AND WE WILL NOT BE LIABLE FOR THE CONSEQUENCES OF ANY INTERRUPTIONS OR ERRORS.</p>
      
         <p><b>15. Representations and Warranties</b></p>

      <p>You hereby represent and warrant to us as follows:</p>

      <p>This Agreement constitutes your legal, valid, and binding obligation, enforceable against you in accordance with its terms, and you agree not to contest the validity or enforceability of this Agreement under the provisions of applicable law relating to whether certain agreements are to be in writing or signed by the party to be bound thereby. Your execution, 
      delivery, and performance of this Agreement and the consummation of the transactions contemplated hereby will not, with or without the giving of notice, the lapse of time, or both, conflict with or violate: (i) any provision of law, rule, or regulation to which you are subject, (ii) any order, judgment, or decree applicable to you or binding upon Your assets or
       properties, (iii) any provision of your by-laws or certificate of incorporation, or (iv) any agreement or other instrument applicable to you or binding upon your assets or properties.  There is no pending or, to the best of your knowledge, threatened claim, action, or proceeding against you, or any affiliate of yours, with respect to the execution, delivery or 
       consummation of this Agreement, or with respect to your site or the Affiliate Materials, and, to the best of your knowledge, there is no basis for any such claim, action or proceeding.</p>

          <p>Any material displayed on your site or Affiliate Materials will not: (a) infringe on any third party’s copyright, patent, trademark, trade secret or other proprietary or intellectual property rights or right of publicity or privacy; (b) violate any applicable law, statute, ordinance or regulation; (c) be defamatory or libelous; (d) be lewd, pornographic or
           obscene; (e) violate any laws regarding unfair competition, anti-discrimination or false advertising; (f) promote violence or contain hate speech; (g) promote discrimination based on race, age, sex, religion, nationality, sexual orientation or disability; or (h) contain viruses, Trojan horses, worms, time bombs, cancelbots or other similar harmful or deleterious programming routines.</p>
     
          <p><b>16. Confidentiality</b></p>

      <p>Except as otherwise provided in this Agreement or with our prior written consent, you agree that all information related to this Agreement and/or to your participation in the Program, including, without limitation, the terms of this Agreement, our business and financial information, our customer lists, and our pricing and sales information, shall remain strictly confidential and shall not be 
      utilized, directly or indirectly, by you for your own business purposes or for any other purpose except and solely to the extent that any such information is generally known or available to the public through a source or sources other than you or your affiliates. Notwithstanding the foregoing, you may deliver a copy of any such information: (a) pursuant to a subpoena issued by any court or administrative agency, (b) to your accountants, attorneys, or other agents on a confidential basis, and (c) otherwise as required by applicable law, rule, regulation or legal process, provided that, in cases involving (a) and (c), you give prior written notice to Target and allow Target to intercede on our own behalf to the extent that Target seeks to limit the disclosure.</p>
     
       <p><b>17. Limitation of Liability</b></p>

      <p>You hereby agree that Target, including without limitation its respective directors, officers, employees, agents, shareholders, members, partners, licensees and licensees, will not be liable for any indirect, special, exemplary, consequential or incidental damages, or any loss of revenue, profits or data, arising in 
      connection with this Agreement, the Program or the Target Vendor, even if we have been advised of the possibility of such damages. Further, our aggregate liability arising with respect to this Agreement and the Program will not exceed the total Referral Fees paid or payable to you under this Agreement in the twelve (12) months immediately preceding the date on which the event giving rise to the most recent claim of liability occurred. </p>
     
        <p><b>18. Indemnification</b></p>

      <p>You acknowledge that by entering into this Agreement, Target does not assume and should not be exposed to the business and operational risks associated with your business, or any aspects of the operation or content of your site(s) and Affiliate Materials. Accordingly, you hereby agree to indemnify, defend and hold harmless Target, our affiliates, licensees and licensors, and each of our respective directors, officers, employees, agents, 
      shareholders and members, from and against any and all claims, actions, demands, liabilities, losses, damages, judgments, settlements, costs and expenses (including court costs and reasonable attorneys fees) (any or all of the foregoing hereinafter referred to as “<b>Losses</b>”), even if such claims are groundless, fraudulent or false, that arise out of or are based on: (i) any breach or alleged breach of any representation or warranty or breach 
      of a covenant or agreement made by you; (ii) the content and/or activities of your site and Affiliate Materials (including, without limitation, any activities or aspects thereof or commerce conducted thereon) or related business; (iii) your use and/or modification of any of the services or materials provided by us or Target Vendor in connection with this Agreement; (iv) your violation of any law, rule, regulation, ordinance or applicable policy 
      of or agreement in connection with the Program or your obligations or services hereunder, including, without limitation, the FTC Guidelines; and/or (v) you or your employees’ negligence or willful misconduct.</p>
     
         <p><b>19. Modification</b></p>

      <p>We may modify any of the terms and conditions contained in this Agreement, at any time and in our sole discretion, by posting a change notice or a new agreement on the Target Vendor or otherwise communicating such change to you. Modifications may include, for example, changes in the scope of available referral fees, fee schedules, payment procedures or Program rules. 
      IF ANY MODIFICATION IS UNACCEPTABLE TO YOU, YOUR ONLY RECOURSE IS TO TERMINATE THIS AGREEMENT. YOUR CONTINUED PARTICIPATION IN THE PROGRAM FOLLOWING OUR POSTING OF A CHANGE NOTICE OR NEW AGREEMENT ON THE TARGET VENDOR WILL CONSTITUTE BINDING ACCEPTANCE OF THE CHANGE.</p>
     
       <p><b>20. Independent Investigation</b></p>

      <p>YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT AND AGREE TO ALL TERMS AND CONDITIONS IN THIS AGREEMENT. You understand that we may at any time (directly or indirectly) solicit customer referrals on terms that may differ from those contained in this Agreement or operate web sites that are similar to or compete with your site. You have independently evaluated the desirability of participating in the Program and are not relying on any representation, guarantee or statement other than as set forth in this Agreement.</p>
      
      
      <p><b>21. General</b></p>

      <p>21.1. <i> No Agency</i>. You and Target are independent contractors, and nothing in this Agreement (including any Offer) will create any partnership, joint venture, agency, franchise, sales representative, or employment relationship between the parties. You will have no authority to make or accept any offers or representations on Target’s behalf. You will not make any statement, whether on your site or otherwise, that reasonably would contradict anything in this Section.</p>

      <p>21.2. <i>Governing Law; Venue</i>. This Agreement has been made in and shall be construed and enforced in accordance with the laws of the United States and the State of Minnesota, without reference to rules governing choice of laws. Any action relating to this Agreement must be brought in the federal or state courts located in Hennepin County, Minnesota, and you irrevocably consent to the jurisdiction of such courts. </p>

      <p>21.3. <i>Notice</i>. Any notices required or permitted by this Agreement must be delivered to Target via registered mail to:</p>

      <p> Target Corporation
      <br> Attn: Affiliate Program Manager</br>
      1000 Nicollet Mall<br/>
      Minneapolis, MN 55403
      </p>

      <p>with a copy to:</p>

      <p>Target Corporation 
      <br> Attn: Vice President, Law</br>
      1000 Nicollet Mall<br/>
      Minneapolis, MN 55403
      </p>

      <p>
      Any notices required or permitted by this Agreement or communications in connection with the Program will be sent to you by Target via email at the address you provided in your Affiliate Application.
      </p>
      
      <p>21.4. <i>Severability; Interpretation</i>. The provisions of this Agreement are independent of and separable from each other, and no provision shall be affected or rendered invalid or unenforceable by virtue of the fact that for any reason any other provision
       may be invalid or unenforceable in whole or in part. In the event of an inconsistency between the terms of this Agreement and the Target Vendor agreement terms and conditions, the terms of this Agreement shall govern.</p>
      
       <p>21.5. <i>Assignment</i>. You may not assign this Agreement, by operation of law or otherwise, without our prior written consent, which may be withheld in our sole discretion. Subject to that restriction, this Agreement 
       will be binding on, inure to the benefit of, and enforceable against the parties and their respective successors and assigns.</p>
     
       <p>21.6. <i>Waiver</i>. Our failure to enforce your strict performance of any provision of this Agreement will not constitute a waiver of our right to subsequently enforce such provision or any other provision of this Agreement.</p>
      
       <p>21.7. <i>Equitable Relief</i>. The parties agree that any breach of either of the party’s obligations regarding trademarks, service marks, trade names, confidentiality, Links or the removal of Links, and/or 
       user data may result in irreparable injury for which there may be no adequate remedy at law. Therefore, in the event of any breach or threatened breach of a party’s obligations regarding trademarks, service marks, 
       trade names, confidentiality, Links or the removal of Links, and/or user data, the aggrieved party will be entitled to seek equitable relief in addition to its other available legal remedies in a court of competent jurisdiction.</p>
     
       <p>21.8. <i>Force Majeure</i>. You acknowledge that Target’s and the Target Vendor’s servers, equipment, and services (e.g., tracking and reporting) may be subject to temporary modifications or shutdowns due to causes 
       beyond Target’s and the Target Vendor’s reasonable control. Such temporary service interruptions will not constitute a material breach of this Agreement.</p>
      
       <p>21.9. <i>Questions</i>. If you have questions about any of the requirements set forth in this Agreement, please contact us at <a href="mailto:targetpartners@target.com">targetpartners@target.com</a>, <a href="mailto:targetcreators@target.com">targetcreators@target.com</a> 
       for clarification and to obtain our written approval for using a tool or other marketing device in connection with your marketing activities in connection with the Program.  Any approval by us of your tools or other
        activities will not, under any circumstances, void, nullify or constitute a waiver of your indemnification obligations hereunder, the independent contractor nature of your engagement or of any of our rights.  
        Any Affiliate found to be in violation of the Agreement is subject to immediate termination from the Program, in addition to all your Referral Fees being forfeited.  We have the right, in our sole discretion, 
        to monitor your marketing activities at any time and from time to time to determine if you are in compliance with the terms of the Agreement and terminate your participation as an Affiliate for any failure to 
        comply with the terms set forth in this Agreement.  If we terminate for your failure to comply with this Agreement, we may withhold payment of Referral Fees.</p>
       
       
       <p><b>Exhibit “A”<br>
       <u>Additional Terms and Conditions Applicable to Affiliate Subnetworks</u></b>
       </p>
       <p>These additional terms and conditions apply to your participation in the Program as an Affiliate Subnetwork:   
           </p>
           <ul>
          
           <li>You must seek prior written approval from Target before choosing to operate as an Affiliate Subnetwork. Such requests must be made in writing and sent to <a href="mailto:targetpartners@target.com">targetpartners@target.com</a>, <a href="mailto:targetcreators@target.com">targetcreators@target.com</a>.</li>
           
           <li>Once you have approval from Target to operate as an Affiliate Subnetwork, you will properly vet each of your sub-affiliate(s) to ensure they meet Target’s Program requirements.  When vetting/reaching out to sub-affiliates, you must instruct each
            sub-affiliate they are required to, prior to engaging them through a written agreement, and confirm they are willing to: (i) follow Target’s brand and advertising guidelines and sign an affiliate agreement; (ii) refrain from making any unsubstantiated
             claims about Target’s products or services; and (iii) disclose all of the sub-affiliate’s content as advertising or sponsored content as required by the FTC Guidelines and Section 5 of the Agreement.</li>
           
            <li>Before permitting any sub-affiliate to participate in the Program, you will ensure each sub-affiliate has entered into a written agreement that requires the sub-affiliate to comply with this Agreement or another agreement approved by Target in writing. </li>
           
            <li>You will regularly train your employees and all sub-affiliates on how to comply with all applicable laws, Section 5 of the Agreement, the FTC Guidelines and Target’s brand and advertising guidelines.  </li>
           
            <li>You will regularly monitor the Affiliates websites, Affiliate Materials and other online comments and public statements of all of your sub-affiliates to ensure their compliance with applicable laws, the Agreement,
             the FTC Guidelines and Target’s brand and advertising guidelines, and provide prompt notice (however, in no event later than two (2) business days) to Target in the event of any non-compliance.</li>
           
            <li>You: (i) must be completely transparent with regards to where traffic from your sub-affiliates originated. This includes restrictions on advertising through toolbars, browser extensions, and any paid placements such as a pay-per-click campaigns.
             Sub-affiliates must also receive approval from you or Target prior to allowing any type of Offer to promote the Program. </li>
          
           <li>You agree that sub-affiliates, content monetization platforms, or third party networks as classified by Target shall not engage in any solicitation, recruitment, or other activities with prospective or current Target affiliates by offering or
           exhibiting payout rates greater than those stated by Target.</li>
          
           <li>You agree to all the terms and conditions of this Agreement on behalf of all sub-affiliates operating under the Program and are responsible for any action or inaction by such sub-affiliates including if any such sub-affiliate does not adhere to or comply with this Agreement.</li>
           
           <li>You must provide to Target a list of all sub-affiliates working within your network within twenty-four (24) hours of any request from Target.</li>
           
           <li>You acknowledge that any violation of the Agreement by any sub-affiliate may result in immediate termination of the remaining sub-affiliates from the Program.</li>
           </ul>

           <p>Please understand that we may monitor your and your sub-affiliates’ activities with regard to compliance with the Agreement and Target may take corrective action, including immediately terminating your relationship with Target, if you do 
           not comply.  Both you and Target potentially could be legally liable if you (or sub-affiliates you engage) fail to disclose their relationship with Target, including receipt of Referral Fees we or you have given you or them.  Further, you will be responsible if you (or your sub-affiliates) make false, atypical or unsubstantiated claims when engaging in communications promoting Target.
           </p>



           
      `,
      styling: {
          textBox: {
              padding: 0,
          },
          heading: {
              color: "#6D6D6D",
              padding: 18,
              fontSize: "2rem",
              textAlign: "center",
          },
          ul: {
              padding: 0,
              margin: 0,
          },
      },
  },
};

export default TermsPage;
