const Settings = {
  creatorsHeaderMenu: {
    items: [
      {
        slug: 'https://creator.target.com/login',
        title: 'Sign in',
      },
      {
        slug: 'https://creator.target.com/onboarding',
        title: 'Apply now',
      },
    ],
  },
  headerMenu: {
    items: [
      {
        slug: 'https://partner.target.com/login.user',
        title: 'Sign in',
      },
      {
        slug: 'https://partner.target.com/signup/none/create-new-mediapartner-account-flow.ihtml?execution=e1s1#/?viewkey=signUpStart',
        title: 'Apply now',
      },
    ],
  },
  footerMenu: [
    {
      slug: 'https://target.com',
      title: 'Target.com',
      target: '_blank'
    },
    {
      slug: '/faqs',
      title: 'FAQs',
    },
    {
      slug: '/termsandconditions',
      title: 'Terms and Conditions',
    },
    
    {
      slug: 'mailto:targetpartners@target.com',
      title: 'Contact Us',
    },

    {
      slug: '/privacypolicy',
      title: 'Privacy Policy',
    }

  ],
  companyData: {
    social: {
      heading: 'Follow us',
      links: {
        facebook: {
          url: 'https://www.facebook.com/target/',
        },
        twitter: {
          url: 'https://x.com/Target',
        },
        instagram: {
          url: 'https://www.instagram.com/target/',
        },
        pinterest: {
          url: 'https://www.pinterest.com/target/',
        },
        tiktok: {
          url: 'https://www.tiktok.com/@target?lang=en',
        },
        threads: {
          url: 'https://www.threads.net/@target',
        },
      },
    },
  },
}

export default Settings
