import React from 'react'
import { Helmet } from 'react-helmet'
import FaqPage from '../SiteData/faqPage'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import TextBox from '../ComponentsList/TextBox/TextBox'
import BreadCrumb from '../ComponentsList/Breadcrumb/Breadcrumb'
import Accordion from '../ComponentsList/Accordion/Accordion'
import FaqBanner from '../ComponentsList/FaqBanner/FaqBanner'



const Faqs = () => {
  const {
    breadcrumb, 
    textBox, 
    accordianBoxOne, 
    brandBanner
  } = FaqPage
  const desktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  return (
    <>
    <Helmet>
    <meta charSet='utf-8' />
    <title>Have more questions on Target Partners?</title>
    {/* Home page specific meta tags for targeted SEO, this overrides the main tags and won't if it's not included here */}
    <meta name='description' content='From how it works to how you can earn and everything in between. Check out our FAQs to know more about the Target affiliates program.' />
    {/* Any specific keywords added here will override the main keywords */}
    <meta name='keywords' content='Target Partners FAQ, Target affiliates FAQ, affiliates program, partners program' />
    {/*Global site tag (gtag.js) - Google Analytics*/}
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-845PXTZEFH"></script>
    <script>{`
       window.dataLayer = window.dataLayer || [];
   function gtag(){dataLayer.push(arguments);}
   gtag('js', new Date());
   gtag('config', 'G-845PXTZEFH');
    `}
    </script>

  </Helmet>
      <div>
        <BreadCrumb values={breadcrumb} desktop={desktop} />
        <TextBox values={textBox} desktop={desktop} />
        <Accordion values={accordianBoxOne} />
        <FaqBanner values={brandBanner} desktop={desktop} />
      </div>
     
    </>
  )
}

export default Faqs

