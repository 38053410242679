import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import idx from "idx";
import { motion } from "framer-motion";

const SmoothLeftToRight = {
  initial: {
    x: -20,
    y: 0,
    opacity: 0,
  },
  animate: {
    x: 0,
    y: 0,
    opacity: 1,
  },
  transition: { duration: 0.7 },
};

const useStyles = makeStyles((theme) => ({
  helloCreatorsBoxBaseStyle: {
    width: "100%",
    maxWidth: "100%",
    position: "relative",
    borderRadius: 0,
    background: "#ffffff",
    display: 'grid',
    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
    },
  },
  helloTextWrapper: {
    position: 'relative',
    minHeight: '200px',
  },
  helloTextItem: {
    position: 'absolute',
    top: '37%',
    left: '90px',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down("sm")]: {
      top: '50%',
      left: '16px',
      right: '16px',
    },
  },
  bannerBackGround: {
    backgroundImage: `url(https://gfc.target.com/partners/target-creators.png)`,
    backgroundPosition: 'center', 
    backgroundSize: 'contain', 
    backgroundRepeat: 'no-repeat',
    margin: '40px',
    height: '600px',
    "@media (max-width: 1440px)": {
      height: '600px',
    },
    [theme.breakpoints.down("xs")]: {
      height: '432px',
      backgroundSize: 'contain',
      backgroundImage: `url(https://gfc.target.com/partners/target-creators.png)`,
    },
  },
  heroText: {
    color: "#000000",
    fontWeight: "normal",
    fontFamily: "HelveticaforTarget-bold",
    fontSize: "4.8rem",
    lineHeight: "4.5rem",
    "@media (max-width: 1440px)": {
      fontSize: "2.5rem",
      lineHeight: "2.3rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.875rem",
      lineHeight: "1.6rem",
      textAlign: 'center',
    },
  },
  bigText: {
    color: "#000000",
    fontSize: "4.8rem",
    lineHeight: "2rem",
    "@media (max-width: 1440px)": {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.875rem",
      textAlign: 'center',
    },
  },
  subTextPrimary: {
    fontSize: "1.5rem",
    marginTop: 20,
    textAlign: "left",
    color: '#6D6D6D',
    lineHeight: "1.5rem",
    "@media (max-width: 1440px)": {
      fontSize: "0.9rem",
      lineHeight: "1rem",

    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6875rem",
      marginTop: 10,
      lineHeight: "1rem",
      textAlign: 'center',
    },
  },


}));

const HelloCreatorsBox = (props) => {
  const { values } = props;
  const classes = useStyles();

  const getValue = (key, index = 0, defaultVal) => {
    const value = idx(values, (_) => _[key][index]);
    return value ? value : defaultVal ? defaultVal : "";
  };

  return (
    <div className={classes.helloCreatorsBoxBaseStyle}>
      <div className={classes.helloTextWrapper}>
        <div className={classes.helloTextItem}>
          <motion.div {...SmoothLeftToRight}>
            <Typography className={classes.heroText} variant={"h2"}>
              {getValue("primaryHeading")} <br />{" "}
              <span className={classes.bigText}>
                {getValue("secondaryHeading")}
              </span> 
            </Typography>
            <Typography color={"primary"} className={classes.subTextPrimary}>
              {getValue("subTextPrimary")}
            </Typography>
          </motion.div>
        </div>
      </div>
      <div className={classes.bannerBackGround}>
      </div>
    </div>
  );
};

export default HelloCreatorsBox;
