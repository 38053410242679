import React, { useEffect } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Box,
  Card,
  CardMedia,
  CardContent,
} from "@material-ui/core";
// import { AnimatedOnScroll } from 'react-animated-css-onscroll'
import { motion, useAnimation } from "framer-motion";
import { SmallZoomEntry } from "../../Utils/AnimationEffects";
import { useInView } from "react-intersection-observer";
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  loveBoxBase: {
    width: "100%",
    position: "relative",
    borderRadius: 0,
    background: "#F4F4F4",
    paddingBottom: theme.spacing(2),
  },
  storiesContainer: {
    marginLeft: "10%",
    marginRight: "10%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5%",
      marginRight: "5%",
    }
  },
  storyCard: {
    background: "transparent",
  },
  headerSection: {
    marginTop: 60,
    [theme.breakpoints.down("md")]: {
      marginTop: 60,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    },
  },
  headerText: {
    textAlign: "center",
    color: "#6D6D6D",
    fontSize: "2.2rem",
    paddingBottom: "80px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.6rem",
      paddingBottom: "0px",

    }
  },

  storyDetails: {
    textAlign: "center",
  },
  title: {
    textAlign: "center",
    fontSize: "1.3rem",
    fontWeight: "Bold",
    paddingBottom: "10px",
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.125rem",
      lineHeight: "1.3rem",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  desc: {
    color: "#000000",
    fontSize: "1rem",
    "@media (max-width: 1024px)": {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("md")]: {
      // fontSize: 15,
    },
    [theme.breakpoints.down("sm")]: {
      // fontSize: 12,
      marginTop: 10,
      lineHeight: "1.3rem"
    },
  },

  storyLink: {
    marginTop: 30,
    borderRadius: 35,
    color: "#CC0000",
    borderColor: "#CC0000",
    // fontSize: 16,
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
      marginTop: 28,
    },
    [theme.breakpoints.down("sm")]: {
      // fontSize: 12,
      marginTop: 26,
    },
  },
  loveBox: {
    padding: theme.spacing(6),
    paddingTop: 0,
    [theme.breakpoints.down("md")]: {
      padding: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  media: {
    padding: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
      paddingTop: 0,
      paddingBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: '65px',
      paddingTop: 0,
      paddingBottom: 0,
    },
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const LoveBox = (props) => {
  const { values, desktop } = props;
  const { loveStories, spacing } = values;
  const classes = useStyles();
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false });

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start("visible");
      }, 300);
    }
  }, [controls, inView]);

  return (
    <>
      <div
        //style={backgroundStyle}
        className={classes.loveBoxBase}
      >
        <Grid container spacing={0} direction="row">
          {values.primaryHeading && (
            <Grid item xs={12} className={classes.headerSection}>
              <Typography className={classes.headerText} variant={"h3"}>
                {values.primaryHeading && values.primaryHeading[0]
                  ? values.primaryHeading[0]
                  : ""}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} className={classes.storiesContainer}>
            <Grid
              container
              justifyContent="center"
              ref={ref}
              spacing={desktop ? (spacing ? spacing : 0) : 0}
            >
              {loveStories &&
                loveStories.length > 0 &&
                loveStories.map((story, storyKey) => {
                  return (
                    <Grid
                      item
                      md={story.width ? story.width : 4}
                      sm={8}
                      xs={11}
                      key={storyKey}
                      className={classes.loveBox}
                    >
                      {/* <AnimatedOnScroll animationIn={'fadeIn'}> */}
                      <motion.div
                        animate={controls}
                        initial="hidden"
                        variants={{
                          visible: { ...SmallZoomEntry.animate, opacity: 1 },
                          hidden: {
                            ...SmallZoomEntry.initial,
                            scale: 0.8,
                            opacity: 0,
                          },
                        }}
                        transition={{
                          duration: 1,
                        }}
                      >
                        <Card className={classes.storyCard} elevation={0}>
                          <CardMedia
                            component="img"
                            className={classes.media}
                            image={`${story.storyImage[0]}`}                          
                          />
                          <CardContent>
                            <Box display="flex" justifyContent="center">
                              <div className={classes.storyDetails}>
                                <Typography className={classes.title}>
                                  {parse(
                                    story.title && story.title[0]
                                      ? story.title[0]
                                      : ""
                                  )}
                                </Typography>
                                <Typography className={classes.desc}>
                                  {story.description &&
                                  story.description[0]
                                    ? story.description[0]
                                    : ""}
                                </Typography>
                                
                              </div>
                            </Box>
                          </CardContent>
                        </Card>
                      </motion.div>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LoveBox;
