import React, { Fragment } from "react";
import { makeStyles, Breadcrumbs, Typography } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
  activeNode: {
    color: "#CC0000",
    fontSize: "0.875rem",
  },

  breadCrumbArea: {
    paddingLeft: theme.spacing(15),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    "& a": {
      textDecoration: "none",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingBottom: theme.spacing(2),
    },
  },
  links: {
    fontSize: "0.875rem",
    color: "#707070",
    marginRight: "0",
  },
}));

const Breadcrumb = (props) => {
  const { values, desktop } = props;
  const { tree } = values;
  const classes = useStyles();

  return (
    <Fragment>
      {tree && tree.length > 0 && (
        <div>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            className={classes.breadCrumbArea}
            maxItems={desktop ? 5 : 3}
          >
            {tree.map((node, nodeIndex) => {
              return node.slug ? (
                <RLink key={nodeIndex} to={node.slug} className={classes.links}>
                  {node.label}
                </RLink>
              ) : node.active ? (
                <Typography key={nodeIndex} className={classes.activeNode} aria-current={node.label}>
                  {node.label}
                </Typography>
              ) : (
                <Typography key={nodeIndex}>{node.label}</Typography>
              );
            })}
          </Breadcrumbs>
        </div>
      )}
    </Fragment>
  );
};

export default Breadcrumb;
